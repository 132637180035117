import React from 'react'
import { Fullscreen, Maximize, Lightbulb, RotateCcw, X } from 'lucide-react'
import { useNextMatchText } from '@/hooks/use-events'
import { Button } from '@/shadcn/components/ui/button'
import { Tooltip } from '@/shadcn/components/_tooltip'
import { useMedia } from 'react-use'
import { toast } from 'sonner'
import { MatchGraph } from '../match-graph-234'
import { ScreenModal } from '@/shadcn/components/_screen-modal'
import { DialogClose } from '@/shadcn/components/ui/dialog'


function QuicklyBar(props) {
  let { event, mutate, feMutate, inScreenModal, matching } = props
  const nextMatchText = useNextMatchText(event)
  const isDesktop = useMedia('(min-width: 450px)')
  // const isTournament = event?.ruleTemplateKey.includes("tournament")

  return (
    <div className='px-4 pb-4 sticky bottom-0 z-30'>
      <div className='border border-collapse bg-background p-3 flex items-center rounded-[10px]'
      >

        {event?.stage === "2-CONFIRM-SEAT" && (
          <div className='flex items-center gap-4 relative'>
            <span className='hidden md:block'>请确认选手席位</span>

            {/* <Button onClick={() => { }} variant="outline">
              {isDesktop ? "重新随机分配" : "重新分配"}
            </Button> */}

            <Button onClick={async () => {
              await event.setStage("3-INPROGRESS")
              mutate()
            }}>
              {isDesktop ? "席位确认完毕，正式开始比赛" : "开始比赛"}
            </Button>
          </div>
        )}

        {event?.stage === "3-INPROGRESS" && (
          <div className='flex items-center'>
            {nextMatchText ? (
              <Button variant="ghost" className="font-number items-center"
                onClick={() => {
                  props.setHighLightMatchNumber(nextMatchText)
                  feMutate()
                  // todo 判断如果定时器存在，就清除并重新计时，以处理用户连点的情况。
                  setTimeout(() => {
                    props.setHighLightMatchNumber(null)
                    feMutate()
                  }, 2000)
                }}
              >
                <Lightbulb className='w-4 mr-1' />
                <div>当前比赛: #{nextMatchText}</div>
              </Button>
            ) : (<div>
              <span className='mr-2'>全部场次已完成记分</span>
              {matching &&  (
                <Button onClick={async () => {
                  await event.setStage("4-FINISHED")
                  mutate()
                }}>确认比赛结束，查看排名表</Button>
              )}
            </div>)}
          </div>
        )}

        {event?.stage === "4-FINISHED" && (
          <div className='pl-2'>比赛已结束。</div>
        )}

        <div className="ml-auto flex">
          <Tooltip label="刷新">
            <Button size="icon" variant="ghost" onClick={() => {
              toast("已刷新", { closeButton: true })
              mutate()
            }}>
              <RotateCcw className='w-5' />
            </Button>
          </Tooltip>

          {isDesktop && !inScreenModal && !props.screenFull && (
            <>
              <ScreenModal
                triggerButton={(
                  <Tooltip label="浏览器全屏">
                    <Button size="icon" variant="ghost" >
                      <Fullscreen className='w-5' />
                    </Button>
                  </Tooltip>
                )}
              >
                <MatchGraph event={event} mutate={mutate} inScreenModal={true} />
              </ScreenModal>
              <Tooltip label={"全屏显示"}>
                <Button size="icon" variant="ghost" className="" onClick={() => {
                  props.toggleFullscreen()
                }}>
                  <Maximize className='w-5' />
                </Button>
              </Tooltip>
            </>
          )}

          {inScreenModal && !props.screenFull && (
            <Tooltip label={"退出浏览器全屏"}>
              <DialogClose asChild>
                <Button size="icon" variant="ghost" className="" >
                  <X className='w-5' />
                </Button>
              </DialogClose>
            </Tooltip>
          )}
          {props.screenFull && (
            <Tooltip label={"退出全屏显示"}>
              <Button size="icon" variant="ghost" onClick={() => props.toggleFullscreen()}>
                <X className='w-5' />
              </Button>
            </Tooltip>
          )}
        </div>

      </div>
    </div>
  )
}

export default QuicklyBar
