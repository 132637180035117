//            [1, 2]
//            [3, 4]
// [9, 5]  -> [6, 9]
// [10, 7] -> [8, 10]

// 额外的在双败中，这里就需要再加一轮双败的结果
// [5, 7] -> ?



export const single4 = [
  { round: 1, number: 1, group: "win", dependents: [] },
  { round: 1, number: 2, group: "win", dependents: [] },
  { round: 2, number: 3, group: "win", dependents: ["1 win", "2 win"] },
]

export const single8 = [
  { round: 1, number: 1, group: "win", dependents: [] },
  { round: 1, number: 2, group: "win", dependents: [] },
  { round: 1, number: 3, group: "win", dependents: [] },
  { round: 1, number: 4, group: "win", dependents: [] },
  { round: 2, number: 5, group: "win", dependents: ["1 win", "2 win"] },
  { round: 2, number: 6, group: "win", dependents: ["3 win", "4 win"] },
  { round: 3, number: 7, group: "win", dependents: ["5 win", "6 win"] },
]

export const single8_3rd = [
  { round: 1, number: 1, group: "win", dependents: [] },
  { round: 1, number: 2, group: "win", dependents: [] },
  { round: 1, number: 3, group: "win", dependents: [] },
  { round: 1, number: 4, group: "win", dependents: [] },
  { round: 2, number: 5, group: "win", dependents: ["1 win", "2 win"] },
  { round: 2, number: 6, group: "win", dependents: ["3 win", "4 win"] },
  { round: 3, number: 7, group: "lose", dependents: ["5 lose", "6 lose"] },
  { round: 3, number: 8, group: "win", dependents: ["5 win", "6 win"] },
]

export const single16 = [
  { round: 1, number: 1, group: "win", dependents: [] },
  { round: 1, number: 2, group: "win", dependents: [] },
  { round: 1, number: 3, group: "win", dependents: [] },
  { round: 1, number: 4, group: "win", dependents: [] },
  { round: 1, number: 5, group: "win", dependents: [] },
  { round: 1, number: 6, group: "win", dependents: [] },
  { round: 1, number: 7, group: "win", dependents: [] },
  { round: 1, number: 8, group: "win", dependents: [] },
  { round: 2, number: 9, group: "win", dependents: ["1 win", "2 win"] },
  { round: 2, number: 10, group: "win", dependents: ["3 win", "4 win"] },
  { round: 2, number: 11, group: "win", dependents: ["5 win", "6 win"] },
  { round: 2, number: 12, group: "win", dependents: ["7 win", "8 win"] },
  { round: 3, number: 13, group: "win", dependents: ["9 win", "10 win"] },
  { round: 3, number: 14, group: "win", dependents: ["11 win", "12 win"] },
  { round: 4, number: 15, group: "win", dependents: ["13 win", "14 win"] },
]

export const single32 = [
  { round: 1, number: 1, group: "win", dependents: [] },
  { round: 1, number: 2, group: "win", dependents: [] },
  { round: 1, number: 3, group: "win", dependents: [] },
  { round: 1, number: 4, group: "win", dependents: [] },
  { round: 1, number: 5, group: "win", dependents: [] },
  { round: 1, number: 6, group: "win", dependents: [] },
  { round: 1, number: 7, group: "win", dependents: [] },
  { round: 1, number: 8, group: "win", dependents: [] },
  { round: 1, number: 9, group: "win", dependents: [] },
  { round: 1, number: 10, group: "win", dependents: [] },
  { round: 1, number: 11, group: "win", dependents: [] },
  { round: 1, number: 12, group: "win", dependents: [] },
  { round: 1, number: 13, group: "win", dependents: [] },
  { round: 1, number: 14, group: "win", dependents: [] },
  { round: 1, number: 15, group: "win", dependents: [] },
  { round: 1, number: 16, group: "win", dependents: [] },

  { round: 2, number: 17, group: "win", dependents: ["1 win", "2 win"] },
  { round: 2, number: 18, group: "win", dependents: ["3 win", "4 win"] },
  { round: 2, number: 19, group: "win", dependents: ["5 win", "6 win"] },
  { round: 2, number: 20, group: "win", dependents: ["7 win", "8 win"] },
  { round: 2, number: 21, group: "win", dependents: ["9 win", "10 win"] },
  { round: 2, number: 22, group: "win", dependents: ["11 win", "12 win"] },
  { round: 2, number: 23, group: "win", dependents: ["13 win", "14 win"] },
  { round: 2, number: 24, group: "win", dependents: ["15 win", "16 win"] },

  { round: 3, number: 25, group: "win", dependents: ["17 win", "18 win"] },
  { round: 3, number: 26, group: "win", dependents: ["19 win", "20 win"] },
  { round: 3, number: 27, group: "win", dependents: ["21 win", "22 win"] },
  { round: 3, number: 28, group: "win", dependents: ["23 win", "24 win"] },

  { round: 4, number: 29, group: "win", dependents: ["25 win", "26 win"] },
  { round: 4, number: 30, group: "win", dependents: ["27 win", "28 win"] },

  { round: 5, number: 31, group: "win", dependents: ["29 win", "30 win"] },
]

export const single64 = [
  { round: 1, number: 1, group: "win", dependents: [] },
  { round: 1, number: 2, group: "win", dependents: [] },
  { round: 1, number: 3, group: "win", dependents: [] },
  { round: 1, number: 4, group: "win", dependents: [] },
  { round: 1, number: 5, group: "win", dependents: [] },
  { round: 1, number: 6, group: "win", dependents: [] },
  { round: 1, number: 7, group: "win", dependents: [] },
  { round: 1, number: 8, group: "win", dependents: [] },
  { round: 1, number: 9, group: "win", dependents: [] },
  { round: 1, number: 10, group: "win", dependents: [] },
  { round: 1, number: 11, group: "win", dependents: [] },
  { round: 1, number: 12, group: "win", dependents: [] },
  { round: 1, number: 13, group: "win", dependents: [] },
  { round: 1, number: 14, group: "win", dependents: [] },
  { round: 1, number: 15, group: "win", dependents: [] },
  { round: 1, number: 16, group: "win", dependents: [] },
  { round: 1, number: 17, group: "win", dependents: [] },
  { round: 1, number: 18, group: "win", dependents: [] },
  { round: 1, number: 19, group: "win", dependents: [] },
  { round: 1, number: 20, group: "win", dependents: [] },
  { round: 1, number: 21, group: "win", dependents: [] },
  { round: 1, number: 22, group: "win", dependents: [] },
  { round: 1, number: 23, group: "win", dependents: [] },
  { round: 1, number: 24, group: "win", dependents: [] },
  { round: 1, number: 25, group: "win", dependents: [] },
  { round: 1, number: 26, group: "win", dependents: [] },
  { round: 1, number: 27, group: "win", dependents: [] },
  { round: 1, number: 28, group: "win", dependents: [] },
  { round: 1, number: 29, group: "win", dependents: [] },
  { round: 1, number: 30, group: "win", dependents: [] },
  { round: 1, number: 31, group: "win", dependents: [] },
  { round: 1, number: 32, group: "win", dependents: [] },

  { round: 2, number: 33, group: "win", dependents: ["1 win", "2 win"] },
  { round: 2, number: 34, group: "win", dependents: ["3 win", "4 win"] },
  { round: 2, number: 35, group: "win", dependents: ["5 win", "6 win"] },
  { round: 2, number: 36, group: "win", dependents: ["7 win", "8 win"] },
  { round: 2, number: 37, group: "win", dependents: ["9 win", "10 win"] },
  { round: 2, number: 38, group: "win", dependents: ["11 win", "12 win"] },
  { round: 2, number: 39, group: "win", dependents: ["13 win", "14 win"] },
  { round: 2, number: 40, group: "win", dependents: ["15 win", "16 win"] },
  { round: 2, number: 41, group: "win", dependents: ["17 win", "18 win"] },
  { round: 2, number: 42, group: "win", dependents: ["19 win", "20 win"] },
  { round: 2, number: 43, group: "win", dependents: ["21 win", "22 win"] },
  { round: 2, number: 44, group: "win", dependents: ["23 win", "24 win"] },
  { round: 2, number: 45, group: "win", dependents: ["25 win", "26 win"] },
  { round: 2, number: 46, group: "win", dependents: ["27 win", "28 win"] },
  { round: 2, number: 47, group: "win", dependents: ["29 win", "30 win"] },
  { round: 2, number: 48, group: "win", dependents: ["31 win", "32 win"] },

  { round: 3, number: 49, group: "win", dependents: ["33 win", "34 win"] },
  { round: 3, number: 50, group: "win", dependents: ["35 win", "36 win"] },
  { round: 3, number: 51, group: "win", dependents: ["37 win", "38 win"] },
  { round: 3, number: 52, group: "win", dependents: ["39 win", "40 win"] },
  { round: 3, number: 53, group: "win", dependents: ["41 win", "42 win"] },
  { round: 3, number: 54, group: "win", dependents: ["43 win", "44 win"] },
  { round: 3, number: 55, group: "win", dependents: ["45 win", "46 win"] },
  { round: 3, number: 56, group: "win", dependents: ["47 win", "48 win"] },

  { round: 4, number: 57, group: "win", dependents: ["49 win", "50 win"] },
  { round: 4, number: 58, group: "win", dependents: ["51 win", "52 win"] },
  { round: 4, number: 59, group: "win", dependents: ["53 win", "54 win"] },
  { round: 4, number: 60, group: "win", dependents: ["55 win", "56 win"] },

  { round: 5, number: 61, group: "win", dependents: ["57 win", "58 win"] },
  { round: 5, number: 62, group: "win", dependents: ["59 win", "60 win"] },
  { round: 6, number: 63, group: "win", dependents: ["61 win", "62 win"] },
]


export const double4 = [
  { round: 1, number: 1, group: "win", dependents: [] },
  { round: 1, number: 2, group: "win", dependents: [] },
  { round: 1, number: 3, group: "lose", dependents: ["1 lose", "2 lose"] },

  { round: 2, number: 4, group: "win", dependents: ["1 win", "2 win"] },
  { round: 2, number: 5, group: "lose", dependents: ["4 lose", "3 win"] },

  { round: 3, number: 6, group: "win", dependents: ["4 win", "5 win"] },
  { round: 4, number: 7, group: "win", dependents: ["4 win", "5 win"], reset: true },
]

export const double8 = [
  // 结算的时候，会通过 "1 win" 或 "1 lose" 的字符串去尝试寻找需要链接的比赛，
  // 然后自动将对应选手的 id 填入
  // 先找到对应的场次，就能找到对应的选手了
  { round: 1, number: 1, group: "win", dependents: [] },
  { round: 1, number: 2, group: "win", dependents: [] },
  { round: 1, number: 3, group: "win", dependents: [] },
  { round: 1, number: 4, group: "win", dependents: [] },
  { round: 1, number: 5, group: "lose", dependents: ["1 lose", "2 lose"] },
  { round: 1, number: 6, group: "lose", dependents: ["3 lose", "4 lose"] },

  { round: 2, number: 7, group: "win", dependents: ["1 win", "2 win"] },
  { round: 2, number: 8, group: "win", dependents: ["3 win", "4 win"] },
  { round: 2, number: 9, group: "lose", dependents: ["5 win", "7 lose"] },
  { round: 2, number: 10, group: "lose", dependents: ["6 win", "8 lose"] },

  { round: 3, number: 11, group: "win", dependents: ["7 win", "8 win"] },
  { round: 3, number: 12, group: "lose", dependents: ["9 win", "10 win"] },
  { round: 4, number: 13, group: "lose", dependents: ["11 lose", "12 win"] }, // 败者组冠军

  { round: 4, number: 14, group: "win", dependents: ["11 win", "13 win"] },
  { round: 5, number: 15, group: "win", dependents: ["11 win", "13 win"], reset: true },
]

export const double8_no_champion = [
  { round: 1, number: 1, group: "win", dependents: [] },
  { round: 1, number: 2, group: "win", dependents: [] },
  { round: 1, number: 3, group: "win", dependents: [] },
  { round: 1, number: 4, group: "win", dependents: [] },
  { round: 1, number: 5, group: "lose", dependents: ["1 lose", "2 lose"] },
  { round: 1, number: 6, group: "lose", dependents: ["3 lose", "4 lose"] },

  { round: 2, number: 7, group: "win", dependents: ["1 win", "2 win"] },
  { round: 2, number: 8, group: "win", dependents: ["3 win", "4 win"] },
  { round: 2, number: 9, group: "lose", dependents: ["5 win", "7 lose"] },
  { round: 2, number: 10, group: "lose", dependents: ["6 win", "8 lose"] },

  { round: 3, number: 11, group: "win", dependents: ["7 win", "8 win"] },
  { round: 3, number: 12, group: "lose", dependents: ["9 win", "10 win"] },
  { round: 4, number: 13, group: "lose", dependents: ["11 lose", "12 win"] }, // 败者组冠军
]

// 预设第一轮选手的 8 人双败
export const double8_preset1 = [
  { round: 1, number: 1, group: "win", dependents: [] },
  { round: 1, number: 2, group: "win", dependents: [] },
  { round: 1, number: 3, group: "lose", dependents: [] },
  { round: 1, number: 4, group: "lose", dependents: [] },

  { round: 2, number: 5, group: "win", dependents: ["1 win", "2 win"] }, // 胜者组冠军
  { round: 2, number: 6, group: "lose", dependents: ["1 lose", "2 lose"] },
  { round: 2, number: 7, group: "lose", dependents: ["3 win", "4 win"] },

  { round: 3, number: 8, group: "lose", dependents: ["6 win", "7 win"] },  // 败者组冠军

  { round: 4, number: 9, group: "win", dependents: ["5 win", "8 win"] },  // 决赛
  { round: 5, number: 10, group: "win", dependents: ["5 win", "8 win"], reset: true },
]

export const double16 = [
  { round: 1, number: 1, group: "win", dependents: [] },
  { round: 1, number: 2, group: "win", dependents: [] },
  { round: 1, number: 3, group: "win", dependents: [] },
  { round: 1, number: 4, group: "win", dependents: [] },
  { round: 1, number: 5, group: "win", dependents: [] },
  { round: 1, number: 6, group: "win", dependents: [] },
  { round: 1, number: 7, group: "win", dependents: [] },
  { round: 1, number: 8, group: "win", dependents: [] },
  { round: 1, number: 9, group: "lose", dependents: ["1 lose", "2 lose"] },
  { round: 1, number: 10, group: "lose", dependents: ["3 lose", "4 lose"] },
  { round: 1, number: 11, group: "lose", dependents: ["5 lose", "6 lose"] },
  { round: 1, number: 12, group: "lose", dependents: ["7 lose", "8 lose"] },

  { round: 2, number: 13, group: "win", dependents: ["1 win", "2 win"] },
  { round: 2, number: 14, group: "win", dependents: ["3 win", "4 win"] },
  { round: 2, number: 15, group: "win", dependents: ["5 win", "6 win"] },
  { round: 2, number: 16, group: "win", dependents: ["7 win", "8 win"] },
  { round: 2, number: 17, group: "lose", dependents: ["9 win", "16 lose"] },
  { round: 2, number: 18, group: "lose", dependents: ["10 win", "15 lose"] },
  { round: 2, number: 19, group: "lose", dependents: ["11 win", "14 lose"] },
  { round: 2, number: 20, group: "lose", dependents: ["12 win", "13 lose"] },

  { round: 3, number: 21, group: "win", dependents: ["13 win", "14 win"] },
  { round: 3, number: 22, group: "win", dependents: ["15 win", "16 win"] },
  { round: 3, number: 23, group: "lose", dependents: ["17 win", "18 win"] },
  { round: 3, number: 24, group: "lose", dependents: ["19 win", "20 win"] },

  { round: 4, number: 25, group: "win", dependents: ["21 win", "22 win"] },
  { round: 4, number: 26, group: "lose", dependents: ["23 win", "21 lose"] }, // 23 win, 21 lose
  { round: 4, number: 27, group: "lose", dependents: ["24 win", "22 lose"] }, // 24 win, 22 lose
  { round: 5, number: 28, group: "lose", dependents: ["26 win", "27 win"] }, // 26 win, 27 win
  { round: 6, number: 29, group: "lose", dependents: ["28 win", "25 lose"] }, // 28 win, 25 lose

  { round: 5, number: 30, group: "win", dependents: ["25 win", "29 win"] }, // 29 win, 25 win
  { round: 6, number: 31, group: "win", dependents: ["25 win", "29 win"], reset: true }, // reset
]

export const double32 = [
  { round: 1, number: 1, group: "win", dependents: [] },
  { round: 1, number: 2, group: "win", dependents: [] },
  { round: 1, number: 3, group: "win", dependents: [] },
  { round: 1, number: 4, group: "win", dependents: [] },
  { round: 1, number: 5, group: "win", dependents: [] },
  { round: 1, number: 6, group: "win", dependents: [] },
  { round: 1, number: 7, group: "win", dependents: [] },
  { round: 1, number: 8, group: "win", dependents: [] },
  { round: 1, number: 9, group: "win", dependents: [] },
  { round: 1, number: 10, group: "win", dependents: [] },
  { round: 1, number: 11, group: "win", dependents: [] },
  { round: 1, number: 12, group: "win", dependents: [] },
  { round: 1, number: 13, group: "win", dependents: [] },
  { round: 1, number: 14, group: "win", dependents: [] },
  { round: 1, number: 15, group: "win", dependents: [] },
  { round: 1, number: 16, group: "win", dependents: [] },
  { round: 1, number: 17, group: "lose", dependents: ["1 lose", "2 lose"] },
  { round: 1, number: 18, group: "lose", dependents: ["3 lose", "4 lose"] },
  { round: 1, number: 19, group: "lose", dependents: ["5 lose", "6 lose"] },
  { round: 1, number: 20, group: "lose", dependents: ["7 lose", "8 lose"] },
  { round: 1, number: 21, group: "lose", dependents: ["9 lose", "10 lose"] },
  { round: 1, number: 22, group: "lose", dependents: ["11 lose", "12 lose"] },
  { round: 1, number: 23, group: "lose", dependents: ["13 lose", "14 lose"] },
  { round: 1, number: 24, group: "lose", dependents: ["15 lose", "16 lose"] },

  { round: 2, number: 25, group: "win", dependents: ["1 win", "2 win"] },
  { round: 2, number: 26, group: "win", dependents: ["3 win", "4 win"] },
  { round: 2, number: 27, group: "win", dependents: ["5 win", "6 win"] },
  { round: 2, number: 28, group: "win", dependents: ["7 win", "8 win"] },
  { round: 2, number: 29, group: "win", dependents: ["9 win", "10 win"] },
  { round: 2, number: 30, group: "win", dependents: ["11 win", "12 win"] },
  { round: 2, number: 31, group: "win", dependents: ["13 win", "14 win"] },
  { round: 2, number: 32, group: "win", dependents: ["15 win", "16 win"] },

  { round: 2, number: 33, group: "lose", dependents: ["17 win", "32 lose"] },
  { round: 2, number: 34, group: "lose", dependents: ["18 win", "31 lose"] },
  { round: 2, number: 35, group: "lose", dependents: ["19 win", "30 lose"] },
  { round: 2, number: 36, group: "lose", dependents: ["20 win", "29 lose"] },
  { round: 2, number: 37, group: "lose", dependents: ["21 win", "28 lose"] },
  { round: 2, number: 38, group: "lose", dependents: ["22 win", "27 lose"] },
  { round: 2, number: 39, group: "lose", dependents: ["23 win", "26 lose"] },
  { round: 2, number: 40, group: "lose", dependents: ["24 win", "25 lose"] },

  { round: 3, number: 41, group: "lose", dependents: ["33 win", "34 win"] },
  { round: 3, number: 42, group: "lose", dependents: ["35 win", "36 win"] },
  { round: 3, number: 43, group: "lose", dependents: ["37 win", "38 win"] },
  { round: 3, number: 44, group: "lose", dependents: ["39 win", "40 win"] },

  { round: 3, number: 45, group: "win", dependents: ["25 win", "26 win"] },
  { round: 3, number: 46, group: "win", dependents: ["27 win", "28 win"] },
  { round: 3, number: 47, group: "win", dependents: ["29 win", "30 win"] },
  { round: 3, number: 48, group: "win", dependents: ["31 win", "32 win"] },

  { round: 4, number: 49, group: "lose", dependents: ["45 lose", "43 win"] },
  { round: 4, number: 50, group: "lose", dependents: ["46 lose", "44 win"] },
  { round: 4, number: 51, group: "lose", dependents: ["47 lose", "41 win"] },
  { round: 4, number: 52, group: "lose", dependents: ["48 lose", "42 win"] },

  { round: 5, number: 53, group: "lose", dependents: ["49 win", "50 win"] },
  { round: 5, number: 54, group: "lose", dependents: ["51 win", "52 win"] },

  { round: 4, number: 55, group: "win", dependents: ["45 win", "46 win"] },
  { round: 4, number: 56, group: "win", dependents: ["47 win", "48 win"] },
  { round: 6, number: 57, group: "lose", dependents: ["54 win", "56 lose"] },
  { round: 6, number: 58, group: "lose", dependents: ["53 win", "55 lose"] },
  { round: 7, number: 59, group: "lose", dependents: ["57 win", "58 win"] },
  { round: 5, number: 60, group: "win", dependents: ["55 win", "56 win"] },
  { round: 8, number: 61, group: "lose", dependents: ["59 win", "60 lose"] },

  { round: 6, number: 62, group: "win", dependents: ["60 win", "61 win"] },
  { round: 7, number: 63, group: "win", dependents: ["60 win", "61 win"], reset: true },
]

export const double64 = [
  { round: 1, number: 1, group: "win", dependents: [] },
  { round: 1, number: 2, group: "win", dependents: [] },
  { round: 1, number: 3, group: "win", dependents: [] },
  { round: 1, number: 4, group: "win", dependents: [] },
  { round: 1, number: 5, group: "win", dependents: [] },
  { round: 1, number: 6, group: "win", dependents: [] },
  { round: 1, number: 7, group: "win", dependents: [] },
  { round: 1, number: 8, group: "win", dependents: [] },
  { round: 1, number: 9, group: "win", dependents: [] },
  { round: 1, number: 10, group: "win", dependents: [] },
  { round: 1, number: 11, group: "win", dependents: [] },
  { round: 1, number: 12, group: "win", dependents: [] },
  { round: 1, number: 13, group: "win", dependents: [] },
  { round: 1, number: 14, group: "win", dependents: [] },
  { round: 1, number: 15, group: "win", dependents: [] },
  { round: 1, number: 16, group: "win", dependents: [] },
  { round: 1, number: 17, group: "win", dependents: [] },
  { round: 1, number: 18, group: "win", dependents: [] },
  { round: 1, number: 19, group: "win", dependents: [] },
  { round: 1, number: 20, group: "win", dependents: [] },
  { round: 1, number: 21, group: "win", dependents: [] },
  { round: 1, number: 22, group: "win", dependents: [] },
  { round: 1, number: 23, group: "win", dependents: [] },
  { round: 1, number: 24, group: "win", dependents: [] },
  { round: 1, number: 25, group: "win", dependents: [] },
  { round: 1, number: 26, group: "win", dependents: [] },
  { round: 1, number: 27, group: "win", dependents: [] },
  { round: 1, number: 28, group: "win", dependents: [] },
  { round: 1, number: 29, group: "win", dependents: [] },
  { round: 1, number: 30, group: "win", dependents: [] },
  { round: 1, number: 31, group: "win", dependents: [] },
  { round: 1, number: 32, group: "win", dependents: [] },

  { round: 1, number: 33, group: "lose", dependents: ["1 lose", "2 lose"] },
  { round: 1, number: 34, group: "lose", dependents: ["3 lose", "4 lose"] },
  { round: 1, number: 35, group: "lose", dependents: ["5 lose", "6 lose"] },
  { round: 1, number: 36, group: "lose", dependents: ["7 lose", "8 lose"] },
  { round: 1, number: 37, group: "lose", dependents: ["9 lose", "10 lose"] },
  { round: 1, number: 38, group: "lose", dependents: ["11 lose", "12 lose"] },
  { round: 1, number: 39, group: "lose", dependents: ["13 lose", "14 lose"] },
  { round: 1, number: 40, group: "lose", dependents: ["15 lose", "16 lose"] },
  { round: 1, number: 41, group: "lose", dependents: ["17 lose", "18 lose"] },
  { round: 1, number: 42, group: "lose", dependents: ["19 lose", "20 lose"] },
  { round: 1, number: 43, group: "lose", dependents: ["21 lose", "22 lose"] },
  { round: 1, number: 44, group: "lose", dependents: ["23 lose", "24 lose"] },
  { round: 1, number: 45, group: "lose", dependents: ["25 lose", "26 lose"] },
  { round: 1, number: 46, group: "lose", dependents: ["27 lose", "28 lose"] },
  { round: 1, number: 47, group: "lose", dependents: ["29 lose", "30 lose"] },
  { round: 1, number: 48, group: "lose", dependents: ["31 lose", "32 lose"] },

  { round: 2, number: 49, group: "win", dependents: ["1 win", "2 win"] },
  { round: 2, number: 50, group: "win", dependents: ["3 win", "4 win"] },
  { round: 2, number: 51, group: "win", dependents: ["5 win", "6 win"] },
  { round: 2, number: 52, group: "win", dependents: ["7 win", "8 win"] },
  { round: 2, number: 53, group: "win", dependents: ["9 win", "10 win"] },
  { round: 2, number: 54, group: "win", dependents: ["11 win", "12 win"] },
  { round: 2, number: 55, group: "win", dependents: ["13 win", "14 win"] },
  { round: 2, number: 56, group: "win", dependents: ["15 win", "16 win"] },
  { round: 2, number: 57, group: "win", dependents: ["17 win", "18 win"] },
  { round: 2, number: 58, group: "win", dependents: ["19 win", "20 win"] },
  { round: 2, number: 59, group: "win", dependents: ["21 win", "22 win"] },
  { round: 2, number: 60, group: "win", dependents: ["23 win", "24 win"] },
  { round: 2, number: 61, group: "win", dependents: ["25 win", "26 win"] },
  { round: 2, number: 62, group: "win", dependents: ["27 win", "28 win"] },
  { round: 2, number: 63, group: "win", dependents: ["29 win", "30 win"] },
  { round: 2, number: 64, group: "win", dependents: ["31 win", "32 win"] },

  { round: 2, number: 65, group: "lose", dependents: ["33 win", "64 lose"] },
  { round: 2, number: 66, group: "lose", dependents: ["34 win", "63 lose"] },
  { round: 2, number: 67, group: "lose", dependents: ["35 win", "62 lose"] },
  { round: 2, number: 68, group: "lose", dependents: ["36 win", "61 lose"] },
  { round: 2, number: 69, group: "lose", dependents: ["37 win", "60 lose"] },
  { round: 2, number: 70, group: "lose", dependents: ["38 win", "59 lose"] },
  { round: 2, number: 71, group: "lose", dependents: ["39 win", "58 lose"] },
  { round: 2, number: 72, group: "lose", dependents: ["40 win", "57 lose"] },
  { round: 2, number: 73, group: "lose", dependents: ["41 win", "56 lose"] },
  { round: 2, number: 74, group: "lose", dependents: ["42 win", "55 lose"] },
  { round: 2, number: 75, group: "lose", dependents: ["43 win", "54 lose"] },
  { round: 2, number: 76, group: "lose", dependents: ["44 win", "53 lose"] },
  { round: 2, number: 77, group: "lose", dependents: ["45 win", "52 lose"] },
  { round: 2, number: 78, group: "lose", dependents: ["46 win", "51 lose"] },
  { round: 2, number: 79, group: "lose", dependents: ["47 win", "50 lose"] },
  { round: 2, number: 80, group: "lose", dependents: ["48 win", "49 lose"] },

  { round: 3, number: 81, group: "lose", dependents: ["65 win", "66 win"] },
  { round: 3, number: 82, group: "lose", dependents: ["67 win", "68 win"] },
  { round: 3, number: 83, group: "lose", dependents: ["69 win", "70 win"] },
  { round: 3, number: 84, group: "lose", dependents: ["71 win", "72 win"] },
  { round: 3, number: 85, group: "lose", dependents: ["73 win", "74 win"] },
  { round: 3, number: 86, group: "lose", dependents: ["75 win", "76 win"] },
  { round: 3, number: 87, group: "lose", dependents: ["77 win", "78 win"] },
  { round: 3, number: 88, group: "lose", dependents: ["79 win", "80 win"] },


  { round: 3, number: 89, group: "win", dependents: ["49 win", "50 win"] },
  { round: 3, number: 90, group: "win", dependents: ["51 win", "52 win"] },
  { round: 3, number: 91, group: "win", dependents: ["53 win", "54 win"] },
  { round: 3, number: 92, group: "win", dependents: ["55 win", "56 win"] },
  { round: 3, number: 93, group: "win", dependents: ["57 win", "58 win"] },
  { round: 3, number: 94, group: "win", dependents: ["59 win", "60 win"] },
  { round: 3, number: 95, group: "win", dependents: ["61 win", "62 win"] },
  { round: 3, number: 96, group: "win", dependents: ["63 win", "64 win"] },


  { round: 4, number: 97, group: "lose", dependents: ["89 lose", "85 win"] },
  { round: 4, number: 98, group: "lose", dependents: ["90 lose", "86 win"] },
  { round: 4, number: 99, group: "lose", dependents: ["91 lose", "87 win"] },
  { round: 4, number: 100, group: "lose", dependents: ["92 lose", "88 win"] },
  { round: 4, number: 101, group: "lose", dependents: ["93 lose", "81 win"] },
  { round: 4, number: 102, group: "lose", dependents: ["94 lose", "82 win"] },
  { round: 4, number: 103, group: "lose", dependents: ["95 lose", "83 win"] },
  { round: 4, number: 104, group: "lose", dependents: ["96 lose", "84 win"] },

  { round: 5, number: 105, group: "lose", dependents: ["97 win", "98 win"] },
  { round: 5, number: 106, group: "lose", dependents: ["99 win", "100 win"] },
  { round: 5, number: 107, group: "lose", dependents: ["101 win", "102 win"] },
  { round: 5, number: 108, group: "lose", dependents: ["103 win", "104 win"] },

  { round: 4, number: 109, group: "win", dependents: ["89 win", "90 win"] },
  { round: 4, number: 110, group: "win", dependents: ["91 win", "92 win"] },
  { round: 4, number: 111, group: "win", dependents: ["93 win", "94 win"] },
  { round: 4, number: 112, group: "win", dependents: ["95 win", "96 win"] },

  { round: 6, number: 113, group: "lose", dependents: ["106 win", "109 lose"] },
  { round: 6, number: 114, group: "lose", dependents: ["105 win", "110 lose"] },
  { round: 6, number: 115, group: "lose", dependents: ["108 win", "111 lose"] },
  { round: 6, number: 116, group: "lose", dependents: ["107 win", "112 lose"] },

  { round: 7, number: 117, group: "lose", dependents: ["113 lose", "114 lose"] },
  { round: 7, number: 118, group: "lose", dependents: ["115 lose", "116 lose"] },

  { round: 5, number: 119, group: "win", dependents: ["109 win", "110 win"] },
  { round: 5, number: 120, group: "win", dependents: ["111 win", "112 win"] },


  { round: 8, number: 121, group: "lose", dependents: ["118 win", "119 lose"] },
  { round: 8, number: 122, group: "lose", dependents: ["117 win", "120 lose"] },
  { round: 9, number: 123, group: "lose", dependents: ["121 win", "122 win"] },

  { round: 6, number: 124, group: "win", dependents: ["119 win", "120 win"] }, // 胜者组决赛

  { round: 10, number: 125, group: "lose", dependents: ["123 win", "124 lose"] }, // 败者组决赛

  { round: 7, number: 126, group: "win", dependents: ["124 win", "125 win"] },  // 总决赛
  { round: 8, number: 127, group: "win", dependents: ["124 win", "125 win"], reset: true},  // Reset
]
