import * as z from "zod"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shadcn/components/ui/form'
import { Button } from '@/shadcn/components/ui/button'
import { useForm } from 'react-hook-form'
import { zodResolver } from "@hookform/resolvers/zod"
import { HeaderText } from '@/components/header-text'

import { RULE_TEMPLATE_KEY } from "@/utils/constants"
import { useEffect, useState } from "react"
import { TournamentGraph } from "@/components/match-table/tournament"
import { Match } from "@/models/match"
import { Player } from "@/models/player"

import { FormGraphMockSlider } from "./form-graph-mock-slider"
import { RadioGroup, RadioGroupItem } from "@/shadcn/components/ui/radio-group"


// 创建映射表，将 playersCount 与配置文件路径关联起来
export const matchesSetMap = {
  single4: () => import('@/models/blueprint/single4'),
  single5: () => import('@/models/blueprint/single5'),
  single6: () => import('@/models/blueprint/single6'),
  single7: () => import('@/models/blueprint/single7'),
  single8: () => import('@/models/blueprint/single8'),
  single9: () => import('@/models/blueprint/single9'),
  single10: () => import('@/models/blueprint/single10'),
  single11: () => import('@/models/blueprint/single11'),
  single12: () => import('@/models/blueprint/single12'),
  single13: () => import('@/models/blueprint/single13'),
  single14: () => import('@/models/blueprint/single14'),
  single15: () => import('@/models/blueprint/single15'),
  single16: () => import('@/models/blueprint/single16'),
  single17: () => import('@/models/blueprint/single17'),
  single18: () => import('@/models/blueprint/single18'),
  single19: () => import('@/models/blueprint/single19'),
  single20: () => import('@/models/blueprint/single20'),
  single21: () => import('@/models/blueprint/single21'),
  single22: () => import('@/models/blueprint/single22'),
  single23: () => import('@/models/blueprint/single23'),
  single24: () => import('@/models/blueprint/single24'),
  single25: () => import('@/models/blueprint/single25'),
  single26: () => import('@/models/blueprint/single26'),
  single27: () => import('@/models/blueprint/single27'),
  single28: () => import('@/models/blueprint/single28'),
  single29: () => import('@/models/blueprint/single29'),
  single30: () => import('@/models/blueprint/single30'),
  single31: () => import('@/models/blueprint/single31'),
  single32: () => import('@/models/blueprint/single32'),
  single33: () => import('@/models/blueprint/single33'),
  single34: () => import('@/models/blueprint/single34'),
  single35: () => import('@/models/blueprint/single35'),
  single36: () => import('@/models/blueprint/single36'),
  single37: () => import('@/models/blueprint/single37'),
  single38: () => import('@/models/blueprint/single38'),
  single39: () => import('@/models/blueprint/single39'),
  single40: () => import('@/models/blueprint/single40'),
  single41: () => import('@/models/blueprint/single41'),
  single42: () => import('@/models/blueprint/single42'),
  single43: () => import('@/models/blueprint/single43'),
  single44: () => import('@/models/blueprint/single44'),
  single45: () => import('@/models/blueprint/single45'),
  single46: () => import('@/models/blueprint/single46'),
  single47: () => import('@/models/blueprint/single47'),
  single48: () => import('@/models/blueprint/single48'),
  single49: () => import('@/models/blueprint/single49'),
  single50: () => import('@/models/blueprint/single50'),
  single51: () => import('@/models/blueprint/single51'),
  single52: () => import('@/models/blueprint/single52'),
  single53: () => import('@/models/blueprint/single53'),
  single54: () => import('@/models/blueprint/single54'),
  single55: () => import('@/models/blueprint/single55'),
  single56: () => import('@/models/blueprint/single56'),
  single57: () => import('@/models/blueprint/single57'),
  single58: () => import('@/models/blueprint/single58'),
  single59: () => import('@/models/blueprint/single59'),
  single60: () => import('@/models/blueprint/single60'),
  single61: () => import('@/models/blueprint/single61'),
  single62: () => import('@/models/blueprint/single62'),
  single63: () => import('@/models/blueprint/single63'),
  single64: () => import('@/models/blueprint/single64'),

  double4: () => import('@/models/blueprint/double4'),
  double5: () => import('@/models/blueprint/double5'),
  double6: () => import('@/models/blueprint/double6'),
  double7: () => import('@/models/blueprint/double7'),
  double8: () => import('@/models/blueprint/double8'),
  double9: () => import('@/models/blueprint/double9'),
  double10: () => import('@/models/blueprint/double10'),
  double11: () => import('@/models/blueprint/double11'),
  double12: () => import('@/models/blueprint/double12'),
  double13: () => import('@/models/blueprint/double13'),
  double14: () => import('@/models/blueprint/double14'),
  double15: () => import('@/models/blueprint/double15'),
  double16: () => import('@/models/blueprint/double16'),
  double17: () => import('@/models/blueprint/double17'),
  double18: () => import('@/models/blueprint/double18'),
  double19: () => import('@/models/blueprint/double19'),
  double20: () => import('@/models/blueprint/double20'),
  double21: () => import('@/models/blueprint/double21'),
  double22: () => import('@/models/blueprint/double22'),
  double23: () => import('@/models/blueprint/double23'),
  double24: () => import('@/models/blueprint/double24'),
  double25: () => import('@/models/blueprint/double25'),
  double26: () => import('@/models/blueprint/double26'),
  double27: () => import('@/models/blueprint/double27'),
  double28: () => import('@/models/blueprint/double28'),
  double29: () => import('@/models/blueprint/double29'),
  double30: () => import('@/models/blueprint/double30'),
  double31: () => import('@/models/blueprint/double31'),
  double32: () => import('@/models/blueprint/double32'),
  double33: () => import('@/models/blueprint/double33'),
  double34: () => import('@/models/blueprint/double34'),
  double35: () => import('@/models/blueprint/double35'),
  double36: () => import('@/models/blueprint/double36'),
  double37: () => import('@/models/blueprint/double37'),
  double38: () => import('@/models/blueprint/double38'),
  double39: () => import('@/models/blueprint/double39'),
  double40: () => import('@/models/blueprint/double40'),
  double41: () => import('@/models/blueprint/double41'),
  double42: () => import('@/models/blueprint/double42'),
  double43: () => import('@/models/blueprint/double43'),
  double44: () => import('@/models/blueprint/double44'),
  double45: () => import('@/models/blueprint/double45'),
  double46: () => import('@/models/blueprint/double46'),
  double47: () => import('@/models/blueprint/double47'),
  double48: () => import('@/models/blueprint/double48'),
  double49: () => import('@/models/blueprint/double49'),
  double50: () => import('@/models/blueprint/double50'),
  double51: () => import('@/models/blueprint/double51'),
  double52: () => import('@/models/blueprint/double52'),
  double53: () => import('@/models/blueprint/double53'),
  double54: () => import('@/models/blueprint/double54'),
  double55: () => import('@/models/blueprint/double55'),
  double56: () => import('@/models/blueprint/double56'),
  double57: () => import('@/models/blueprint/double57'),
  double58: () => import('@/models/blueprint/double58'),
  double59: () => import('@/models/blueprint/double59'),
  double60: () => import('@/models/blueprint/double60'),
  double61: () => import('@/models/blueprint/double61'),
  double62: () => import('@/models/blueprint/double62'),
  double63: () => import('@/models/blueprint/double63'),
  double64: () => import('@/models/blueprint/double64'),
}

const formSchema = z.object({
  // basic
  name: z.string().min(2, { message: "赛事名称需至少两个字符。" }),
  gameKey: z.string({ required_error: "Select a game or sport", }),
  description: z.string(),
  poster: z.string(),
  visibility: z.string(),

  // rule
  stageCount: z.string(),
  ruleTemplateKey: z.string(),

  // players
  attendeesIds: z.array(z.string()).optional(),
  registrationPage: z.boolean(),
  playersCount: z.number(),
})


export const CreateEventSetPage = () => {
  const [previewMatches, setPreviewMatches] = useState([])
  // const [es1] = useState([])
  // const [es2] = useState([]) // 单阶段比赛此值无用

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      playersCount: 4,

      ruleTemplateKey: RULE_TEMPLATE_KEY.to2,
      stageCount: "1",

      description: "",
      gameKey: "sf6",
      visibility: "public",
      attendeeIds: [],
      poster: "",
      registrationPage: false,

    },
  })
  let watch = form.watch()

  useEffect(() => {
    mutatePreview()
    // eslint-disable-next-line
  }, [watch.playersCount, watch.stageCount])

  const mutatePreview = async () => {
    let offset = 1
    let matchesSet = []
    console.log(watch.stageCount)
    let loadMatchesSet = null
    if (watch.stageCount === '1') {
      loadMatchesSet = matchesSetMap[`single${watch.playersCount}`]
    }
    if (watch.stageCount === '2') {
      loadMatchesSet = matchesSetMap[`double${watch.playersCount}`]
    }

    // 根据 playersCount 动态加载相应的配置文件
    if (loadMatchesSet) {
      const module = await loadMatchesSet()
      matchesSet = module.default
    }

    const pms = matchesSet.map((i, idx) => {
      let scoreboard = []
      if (i.dependents.length === 0) {
        scoreboard = [
          { playerId: offset, player: new Player({ name: `选手${offset}` }), value: "0" },
          { playerId: offset + 1, player: new Player({ name: `选手${offset + 1}` }), value: "0" },
        ]
        offset += 2
      }
      if (i.dependents.length === 1) {
        scoreboard = [
          { playerId: '', value: '' },
          { playerId: offset, player: new Player({ name: `选手${offset}` }), value: "0" },
        ]
        offset += 1
      }
      return new Match({
        _id: i.number,
        scoreboard: scoreboard,
        tournamentProperties: i,
      })
      // return {
      //   id: i.number,
      //   scoreboard: [],
      //   tournamentProperties: i,
      // }
    })
    setPreviewMatches(pms)
  }

  const onCreate = () => {
    // console.log(form.getValues())
    // let es = {
    //   multiStages: [
    //     { stage: 1, events: es1 },
    //     { stage: 2, events: es2 },
    //   ]
    //   //   es1[0].settlement: {
    //   //     toNextEventId: "...",
    //   //     methodKey: "top2", // "top1", "top8"
    //   //   },
    // }
    console.log('保存阶段')
  }

  return (
    <div>
      <HeaderText>New Event(Beta)</HeaderText>
      <Form {...form}>
        <form className="" onSubmit={(e) => { e.preventDefault() }} >
          <div className='flex mt-8 phone:flex-col phone:p-2'>
            <div className="pad:w-36 w-48 text-xl self-start flex shrink-0">基本信息</div>
            <div className='flex-grow phone:mt-4 space-y-8'>
              <div className="h-32 bg-zinc-700/50 rounded-lg"></div>
              {/* <FormNameInput form={form} />
              <FormGamesPicker form={form} />
              <FormRulePicker form={form} />
              <FormPosterPicker form={form} />
              <FormDescriptionInput form={form} />
              <FormVisibilityPicker form={form} />
              <FormGraphMockSlider form={form} /> */}

              <FormGraphMockSlider form={form} />
            </div>
          </div>
          <div className='flex mt-8 phone:flex-col phone:p-2'>
            <div className="pad:w-36 w-48 text-xl self-start flex shrink-0">规则</div>

            <div className='flex-grow phone:mt-4 space-y-8'>
              <FormField
                control={form.control}
                name="stageCount"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>阶段</FormLabel>
                    <FormControl>
                      <RadioGroup value={field.value} onValueChange={(e) => field.onChange(e)}>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="1" />
                          </FormControl>
                          <FormLabel className="">单阶段比赛，一场比赛即可决出胜负</FormLabel>
                        </FormItem>
                        <FormItem className="flex items-center space-x-3 space-y-0">
                          <FormControl>
                            <RadioGroupItem value="2" />
                          </FormControl>
                          <FormLabel className="">双阶段比赛，一阶段产生多个分组，根据规则晋级至二阶段完赛</FormLabel>
                        </FormItem>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {watch.stageCount === "1" && (
                <div className="bg-zinc-100 px-4 py-2 rounded-lg">
                  <FormField
                    control={form.control}
                    name="esRule1"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>一阶段规则</FormLabel>
                        <FormControl>
                          <div className="flex pl-8">
                            <RadioGroup value={field.value} onValueChange={(e) => field.onChange(e)}>
                              <FormItem className="flex items-center space-x-3 space-y-0">
                                <FormControl>
                                  <RadioGroupItem value="tournament-1" />
                                </FormControl>
                                <FormLabel className="">单败淘汰赛</FormLabel>
                              </FormItem>
                              <FormItem className="flex items-center space-x-3 space-y-0">
                                <FormControl>
                                  <RadioGroupItem value="tournament-2" />
                                </FormControl>
                                <FormLabel className="">双败淘汰赛</FormLabel>
                              </FormItem>
                              <FormItem className="flex items-center space-x-3 space-y-0">
                                <FormControl>
                                  <RadioGroupItem value="swiss-1" />
                                </FormControl>
                                <FormLabel className="">瑞士轮</FormLabel>
                              </FormItem>
                            </RadioGroup>

                            <div>
                              Priview
                            </div>
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />


                </div>
              )}

            </div>
          </div>
        </form>
      </Form>

      <div className='flex mt-16 phone:flex-col phone:p-2'>
        <div className="w-full">
          <Button className="w-full mt-4"
            onClick={onCreate}>
            创建比赛
          </Button>
        </div>
      </div>

      <div className="w-[1000px] overflow-scroll">
        <TournamentGraph matches={previewMatches} />
      </div>
    </div>
  )
}


// let es = {
//   "_id": "",
//   "owner": "",
//   "name": "",
//   "description": "",
//   "gameKey": "sf6",
//   "ruleTemplateKey": "tournament-1",
//   "startDate": "",
//   "stage": "3-INPROGRESS",
//   "attendeeIds": ["..", "..."],
//   "visibility": "public",
//   "poster": "https://oss.laf.run/cd6zkd-user-upload-images/posts/sf6-3.jpg",
//   multiStages: [
//     { stage: 1, events: [e, e, e, e] },
//     { stage: 2, events: [e] },
//   ],
// }

// let e = {
//   settlement: {
//     toNextEventId: "...",
//     methodKey: "top2", // "top1", "top8"
//   },

//   "_id": "6641797fe6d7130c4bc6e9d3",
//   "owner": "663dfa63a71618163e30f867",
//   "name": "PosterEvent",
//   "description": "",
//   "gameKey": "sf6",
//   "ruleTemplateKey": "tournament-1",
//   "startDate": "",
//   "stage": "2-CONFIRM-SEAT",
//   "attendeeIds": [
//     "664f673625a995208bbef2c9",
//     "664f673625a995208bbef2c6",
//     "664f673625a995208bbef2c7",
//     "664f673625a995208bbef2ca",
//     "664f673625a995208bbef2c8"
//   ],
//   "visibility": "public",
//   "poster": "https://oss.laf.run/cd6zkd-user-upload-images/posts/sf6-3.jpg",
// }