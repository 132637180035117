// 第二层，分析规则和模式，生成 dataset，给 peg board，得到对阵表视图给 detail
import React, { useRef, useState } from 'react'
import { cn } from '@/shadcn/utils'
import { useBoolean, useFullscreen } from 'react-use'
import QuicklyBar from './graph-blocks/quickly-bar-in-graph'
import { SwissBoard } from './graph-blocks/swiss-board'
import { RULE_TEMPLATE_KEY } from '@/utils/constants'
import { TournamentGraph } from '@/components/match-table/tournament'


const spacerX = (x) => x * 290
const spacerY = (y) => y * 100


export const getMatchesContentSize = (matches) => {
  if (!matches) {
    return {
      width: 300,
      height: 300
    }
  }
  let mostX = Math.max(...matches.map(i => i.x))
  let mostY = Math.max(...matches.map(i => i.y))
  return {
    width: spacerX(mostX + 1) + 60,
    height: spacerY(mostY + 1) + 160 // 这 160 人工修正，为了操作栏空间
  }
}

export function MatchGraph({ event, mutate, matching, inScreenModal }) {
  const [, setHighLightMatchNumber] = useState(null)
  const board = useRef(null)
  const matches = event?.matchController?.matches
  // let { width: contentWidth, height: contentHeight } = getMatchesContentSize(matches)
  // 全屏相关
  const graphRef = useRef(null)
  const [fs, toggleFullscreen] = useBoolean(false) // 占满显示器
  const screenFull = useFullscreen(graphRef, fs, { onClose: () => toggleFullscreen(false) })


  const reload = async () => {
    await mutate()
    await board.current?.reload()
  }
  // let dataset = useMatchesDataset(event, matches, (node) => {
  //   // let number = i.tournamentProperties?.number
  //   // let flash = number === highLightMatchNumber && event?.stage === "3-INPROGRESS"
  //   let i = matches.find(m => m.id === node.key)
  //   return (
  //     <div key={i.id}
  //       data-match-id={i.id}
  //       className={cn(
  //         "absolute duration-1000 rounded-sm",
  //       )}
  //     >
  //       <MatchSmallCard
  //         stage={event?.stage}
  //         match={i}
  //         reload={reload}
  //       />
  //     </div>
  //   )
  // })
  const r = event.ruleTemplateKey
  const isSwiss = r === RULE_TEMPLATE_KEY.sw1
  const isTournament = [RULE_TEMPLATE_KEY.to1, RULE_TEMPLATE_KEY.to2, RULE_TEMPLATE_KEY.to2_no_champion].includes(r)


  return (
    <div ref={graphRef} className={cn({ "overflow-scroll": screenFull })}>
      <div className={cn(
        "w-full relative p-1",
        { "min-h-[calc(100dvh_-_6rem)]": screenFull || inScreenModal },
      )}>
        {matches && isSwiss && (
          <SwissBoard event={event} reload={reload} matching={matching} />
        )}
        {matches && isTournament && (
          <TournamentGraph
            matches={matches}
            screenFull={screenFull || inScreenModal}
            controllerProps={{ reload, stage: event.stage }} />
        )}
      </div>
      <QuicklyBar
        event={event}
        mutate={reload}
        feMutate={() => board.current?.reload()}
        screenFull={screenFull}
        toggleFullscreen={toggleFullscreen}
        inScreenModal={inScreenModal}
        setHighLightMatchNumber={setHighLightMatchNumber}
        matching={matching}
      />
    </div>
  )
}

