import {  FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shadcn/components/ui/form'
import { Input } from '@/shadcn/components/ui/input'

export const FormNameInput = ({ form }) => {
  return (
    <FormField
      control={form.control}
      name="name"
      render={({ field }) => (
        <FormItem className="">
          <FormLabel>名称</FormLabel>
          <FormControl>
            <Input {...field} placeholder="" onChange={e => field.onChange(e)} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
