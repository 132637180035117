
export const RULE_TEMPLATE_KEY = {
  "to1": "tournament-1", // 单败决出冠军
  "to2": "tournament-2", // 双败决出冠军
  "to2_no_champion": "tournament-2:no-champion", // 双败决出冠军
  "sw1": "swiss-1",

  "to1_3rd": "tournament-1-3rd", // 单败，3、4 名额外加赛一场，决出冠亚季军
  // "mto_1": "multiple-tournament-1",
}

export const EVENT_STAGE = {
  "stage1": "1-REGISTRATION",
  "stage2": "2-CONFIRM-SEAT",
  "stage3": "3-INPROGRESS",
  "stage4": "4-FINISHED",
}


export const GAME_KEY = {
  "sf6": "sf6",
  "sf5": "sf5",
  "smash": "smash",
  "kof15": "kof15",
  "kof14": "kof14",
}
