

const requestHost = () => {
  if (process.env.REACT_APP_ENV === "development") {
    return "https://dev-api.saing.games"
  }
  return 'https://api.saing.games'
}

const post = async (url, body, headers) => {
  let host = requestHost()
  if (url.startsWith('/')) {
    url = `${host}${url}`
  }
  let token = localStorage.getItem("access_token")

  // 如果连接一个坏掉的 Wi-Fi 会导致这里报错
  try {
    let response = await fetch(url, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        ...headers,
      },
      body: JSON.stringify(body),
    })
    return await response.json()
  } catch {
    console.warn("发起请求失败，请检查网络状况。")
    return { data: null }
  }
}

const upload = async (url, file, headers) => {
  let host = requestHost()
  if (url.startsWith('/')) {
    url = `${host}${url}`
  }
  let token = localStorage.getItem("access_token")
  const formData = new FormData()
  formData.append(`user-upload-file`, file)
  let response = await fetch(url, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${token}`,
      ...headers,
    },
    body: formData,
  })
  return await response.json()

}
export { post, upload }

