import React from 'react'
import { Button } from '@/shadcn/components/ui/button'
import { useParams } from 'react-router-dom'
import { useCommunityFullData } from '@/hooks/use-communities'
import { CommunityMembers } from './blocks/members'
import { HeaderText } from '@/components/header-text'

export function Community() {
  const params = useParams()
  const { data: community } = useCommunityFullData(params.id)

  return (
    <div className='flex flex-col gap-4'>
      <HeaderText>{community.name}</HeaderText>

      <div>由 {community.organizer?.displayName} 管理</div>

      {/* <div>
        <div className='font-bold'>社区赛事</div>
        <div className='h-32 bg-zinc-200 rounded-lg'>
        </div>
      </div> */}

      {Array.isArray(community?.members) && <CommunityMembers community={community} />}

      {/* <div>
        <div className='font-bold'>Join Wait List</div>
        <div className='h-32 bg-zinc-200 rounded-lg'>
        </div>
      </div> */}


      <div className='hidden gap-2'>
        <Button>申请加入社区(todo)</Button>
        <Button>退出社区(todo)</Button>
      </div>
    </div>
  )
}
