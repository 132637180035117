import { Command } from '@/shadcn/components/ui/command'
import { cn } from '@/shadcn/utils'
import { Button } from '@/shadcn/components/ui/button'
import { post } from '@/utils/laf-db'
import { toast } from 'sonner'
import { ContentUnavailable } from '@/shadcn/components/_content-unavailable'
import { useEventsPickerSource } from '@/hooks/use-events'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/shadcn/components/ui/dropdown-menu"
import { frontendRanking } from '@/hooks/use-fe-ranking'
import { GameEvent } from '@/models/game-event'
import { MatchController } from '@/models/match'
import { RULE_TEMPLATE_KEY } from '@/utils/constants'

const getRanking = async (pickedEvent) => {
  let ranking = []
  let { ruleTemplateKey } = pickedEvent

  if ([RULE_TEMPLATE_KEY.to1, RULE_TEMPLATE_KEY.to2].includes(ruleTemplateKey)) {
    let res = await post(`/public-read/events-detail`, { eventId: pickedEvent.id })
    let e = new GameEvent(res.data)
    if (e.attendeeIds?.length > 0) {
      let { data: matches } = await post('/public-read/matches', { eventId: pickedEvent.id, location: "topology" })
      e.matchController = new MatchController(matches, e)
    }
    let fe = frontendRanking(e)
    ranking = fe.ranking || []
  } else if (ruleTemplateKey === RULE_TEMPLATE_KEY.sw1) {
    let res = await post(`/public-read/swiss-ranking`, { eventId: pickedEvent.id })
    ranking = res?.data || []
  }
  return ranking
}

// 选择一场比赛，并提供一些预设来选择其中的参赛者
export function SuccessorPicker({ event, mutate, close }) {
  // 正常选择
  const { data: events } = useEventsPickerSource()
  const onPicked = async (pickedEvent, slicer) => {
    let ranking = await getRanking(pickedEvent)

    let playerIds = ranking.map(i => i.playerId).slice(...slicer)
    let ids = [...new Set(event.attendeeIds.concat(playerIds))]
    let payload = {
      eventId: event.id,
      attendeeIds: ids,
    }
    await post(`/events/set`, payload)
    await mutate()
    close()

    let addCount = ids.length - event.attendeeIds.length
    toast.success(`已为 ${addCount} 位选手报名`, { duration: 3000 })
  }

  if (Array.isArray(events) === false || events?.length === 0) {
    return <ContentUnavailable className="h-80" title="暂无可继承选手的比赛" description="请确保您是比赛的管理员，且比赛被标记为结束状态。" />
  }

  return (
    <Command className="border h-80 relative">
      <div className={cn('p-2 overflow-scroll flex flex-col gap-2 no-scrollbar')}>
        {events.map((i) => {
          return (
            <DropdownMenu key={i.id} >
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="widthFull" className="flex justify-between" >
                  <span>{i.name}</span>
                  <div className='bg-secondary p-2 text-xs font-number rounded'>
                    {i.attendeeIds.length}
                  </div>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="">
                <DropdownMenuLabel>从 {i.attendeeIds.length} 位选手中选择</DropdownMenuLabel>
                <DropdownMenuSeparator />

                <DropdownMenuItem className="cursor-pointer" onClick={() => onPicked(i, [0, 4])}>
                  <div className='flex gap-0.5 mr-1'>
                    <div className='w-2 h-2 rounded-[2px] bg-winner'></div>
                    <div className='w-2 h-2 rounded-[2px] border bg-secondary'></div>
                    <div className='w-2 h-2 rounded-[2px] border bg-secondary'></div>
                  </div>
                  <span>前 4 名选手</span>
                </DropdownMenuItem>
                <DropdownMenuItem className="cursor-pointer" onClick={() => onPicked(i, [0, 8])}>
                  <div className='flex gap-0.5 mr-1'>
                    <div className='w-2 h-2 rounded-[2px] bg-winner'></div>
                    <div className='w-2 h-2 rounded-[2px] border bg-secondary'></div>
                    <div className='w-2 h-2 rounded-[2px] border bg-secondary'></div>
                  </div>
                  <span>前 8 名选手</span>
                </DropdownMenuItem>
                <DropdownMenuItem className="cursor-pointer" onClick={() => onPicked(i, [-8])}>
                  <div className='flex gap-0.5 mr-1'>
                    <div className='w-2 h-2 rounded-[2px] border bg-secondary'></div>
                    <div className='w-2 h-2 rounded-[2px] border bg-secondary'></div>
                    <div className='w-2 h-2 rounded-[2px] bg-winner'></div>
                  </div>
                  <span>后 8 名选手</span>
                </DropdownMenuItem>
                <DropdownMenuItem className="cursor-pointer" onClick={() => onPicked(i, [])}>
                  <div className='flex gap-0.5 mr-1'>
                    <div className='w-2 h-2 rounded-[2px] bg-winner'></div>
                    <div className='w-2 h-2 rounded-[2px] bg-winner'></div>
                    <div className='w-2 h-2 rounded-[2px] bg-winner'></div>
                  </div>
                  <span>全部选手</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )
        })}
      </div>
    </Command>
  )
}