import useSWR from 'swr'
import { post } from '@/utils/laf-db'
import { GameEvent } from '@/models/game-event'
import { MatchController } from '@/models/match'
import { useCurrentUser } from './use-session'
import { RULE_TEMPLATE_KEY } from '@/utils/constants'

export const useGameEvents = () => {
  const fetcher = async () => {
    let { data } = await post('/public-read/events')
    const events = data.map((i) => new GameEvent(i))
    let publicEvents = events.filter(i => i.visibility === "public").slice(0, 10)
    let privateEvents = events.filter(i => i.visibility === "private")
    return { publicEvents, privateEvents }
  }
  const swrResult = useSWR('laf-sdk:get-event', fetcher, {})
  return swrResult
}

export const useMyRegisteredEvents = (playerId) => {
  const fetcher = async () => {
    if (!playerId) {
      return []
    }
    let { data } = await post('/public-read/events', {
      filter: {
        attendeeIds: playerId
      }
    })
    const events = data.map((i) => new GameEvent(i))
    return events
  }
  const swrResult = useSWR(`laf-sdk:get-event-my-registered:${playerId}`, fetcher, {})
  return swrResult
}


export const useGameEventFullData = (eventId) => {
  const fetcher = async () => {
    let { data: event } = await post('/public-read/events-detail', { eventId })
    let e = event ? new GameEvent(event) : {}
    // 接口自动返回选手信息

    if (e.attendeeIds?.length > 0) {
      let { data: matches } = await post('/public-read/matches', { eventId, location: "topology" })
      e.matchController = new MatchController(matches, e)
    }
    return e
  }

  const swrResult = useSWR(`laf-sdk:get-event/${eventId}`, fetcher)

  return swrResult
}

// 找下一个正在进行的比赛（仅 quicklyBar 使用）
export const useNextMatchText = (event) => {
  const matches = event?.matchController?.matches
  const isInProgress = event?.stage === "3-INPROGRESS"

  if (isInProgress && Array.isArray(matches)) {
    let m = matches.find(i => {
      return i.stage === "in progress" && i.scoreboard.length > 0
    })

    if (event.ruleTemplateKey === RULE_TEMPLATE_KEY.sw1) {
      return m?.swissProperties?.number
    }

    return m?.tournamentProperties?.number
  }

  return ""
}

// 仅限继承选择器使用的数据源
export const useEventsPickerSource = () => {
  let { data: user } = useCurrentUser()
  const fetcher = async () => {
    let filter = { stage: "4-FINISHED", owner: user.id }
    let { data } = await post('/public-read/events', { filter })
    const events = data.map((i) => new GameEvent(i))
    return events
  }

  const swrResult = useSWR('laf-sdk:get-event', fetcher, {})
  // swrResult.data maybe is undefined
  return swrResult
}