import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shadcn/components/ui/form'
import { Textarea } from '@/shadcn/components/ui/textarea'

export const FormDescriptionInput = ({ form }) => {
  return (
    <FormField
      control={form.control}
      name="description"
      render={({ field }) => (
        <FormItem className="">
          <FormLabel>描述</FormLabel>
          <FormControl>
            <Textarea {...field}
              onChange={e => {
                field.onChange(e)
              }}
              className="resize-none"
              placeholder=""
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
