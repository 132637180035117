import React, { useState } from 'react'
import { Dices, Armchair, WorkflowIcon, GripVerticalIcon } from 'lucide-react'
import { PlayerSmallCard } from '@/components/player-card'
import { Button } from '@/shadcn/components/ui/button'
import { Modal } from '@/shadcn/components/_modal'
import { post } from '@/utils/laf-db'
import { PlayerAvatar } from '@/shadcn/components/_player-avatar'
import { Confirm } from '@/shadcn/components/_confirm'
import { PlayersPicker } from '@/components/players-picker'
import { cn } from '@/shadcn/utils'
import { useCurrentUser } from '@/hooks/use-session'
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, rectSortingStrategy, SortableContext, sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { SuccessorPicker } from '@/components/successor-picker'

const SortableItem = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef}
      className={cn(
        { 'z-[1000]': attributes["aria-pressed"] },
        "hover:bg-secondary cursor-pointer rounded-lg flex justify-between group"
      )}
      style={style}
    >
      <div className='w-[85%]'>{props.children}</div>
      {props.matching && (
        <button className='opacity-0 group-hover:opacity-100 shrink-0 w-6' {...attributes} {...listeners}><GripVerticalIcon /></button>
      )}
    </div>
  );
}



export const Attendees = ({ event, mutate, matching }) => {
  let isPreparing = event?.stage === "1-REGISTRATION"
  const [selectedIds, setSelectedIds] = useState(event?.attendeeIds || [])
  const { data: account } = useCurrentUser()
  const attendees = event?.attendees || []
  const [loading, setLoading] = useState(false)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  if (!isPreparing && event?.attendees) {
    return (
      <div className="flex flex-col p-4">
        <div className='mb-4'>
          Total {event.attendees.length} Attendees
        </div>
        <div className="flex flex-wrap ">
          {event.attendees.map(player => {
            return <PlayerAvatar key={player.id} player={player} className='w-8 h-8 bg-primary-foreground -mr-2 mb-2' />
          })}
        </div>
      </div>
    )
  }

  const save = async () => {
    let url = `/events/set`
    await post(url, { eventId: event.id, attendeeIds: selectedIds })
    await mutate()
  }

  const remove = async (i) => {
    let url = `/events/delete-attendees`
    await post(url, { eventId: event.id, attendeeIds: [i.id] })
    await mutate()
    setSelectedIds(selectedIds.filter(id => id !== i.id))
  }

  const handleDragEnd = async ({ active, over }) => {
    if (active.id !== over.id) {
      setLoading(true)
      const oldIndex = attendees.findIndex(i => i.id === active.id)
      const newIndex = attendees.findIndex(i => i.id === over.id)
      let newAttendees = arrayMove(attendees, oldIndex, newIndex)
      // setAttendees(newAttendees)
      let newAttendeeIds = newAttendees.map(i => i.id)
      let url = `/events/set`
      await post(url, { eventId: event.id, attendeeIds: newAttendeeIds })
      await mutate()
      setTimeout(() => {
        setLoading(false)
      }, 200)
    }
  }


  return (
    <div className='h-full flex flex-col relative'>
      <div className='flex flex-col justify-between p-4' >
        <div className=''>
          Total {event?.attendees?.length} Attendees
        </div>
        {matching && isPreparing && (
          // <div className='flex gap-2 mt-2 w-full'>
          <div className='grid grid-cols-3 gap-2 mt-2'>
            <Confirm
              confirmText="重置选手次序"
              title="确定重置选手次序吗？"
              description="重置选手次序会随机为选手分配新的编号，可使部分赛制的人为因素减少。"
              triggerButton={(
                <Button size="sm" variant="outline" className="w-full" >
                  <Dices className='w-4 h-4 mr-1' />
                  <span>乱序</span>
                </Button>
              )}
              confirmAction={async () => {
                if (event?.randomAttendees) {
                  await event.randomAttendees()
                  await mutate()
                }
              }}
            >
            </Confirm>
            <Modal
              title="从选手池中选择选手并调整"
              triggerButton={
                <Button size="sm" variant="outline" className="w-full">
                  <Armchair className='w-4 h-4 mr-1' />
                  <span>调选手</span>
                </Button>
              }
              render={({ close }) => {
                return (
                  <>
                    <PlayersPicker
                      value={selectedIds}
                      onChange={setSelectedIds}
                      option={{ cleanable: true }}
                    />
                    <Button onClick={async () => {
                      await save()
                      close()
                    }}>Save</Button>
                  </>
                )
              }}
            />
            <Modal
              title="从已结束的比赛中继承选手"
              triggerButton={
                <Button size="sm" variant="outline" className="w-full" >
                  <WorkflowIcon className='w-4 h-4 mr-1' />
                  <span>继承</span>
                </Button>
              }
              render={({ close }) => {
                return (<SuccessorPicker event={event} mutate={mutate} close={close} />)
              }}
            />
          </div>
        )}

      </div>

      {(loading && (
        <div className="absolute rounded-lg z-10 top-0 left-0 w-full h-full bg-background/80 flex-center">
          Loading...
        </div>
      ))}

      <div className='overflow-scroll px-4 pb-4 grid grid-cols-2 no-scrollbar'>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={attendees}
            strategy={rectSortingStrategy}
          >
            {attendees.map(i => {
              let isAccountPlayer = i.asAccount === true && i.owner === account?.id
              let canRemoveAnyAttendee = isPreparing && matching && isAccountPlayer === false

              return (
                <SortableItem attendee={i} key={i.id} id={i.id} matching={canRemoveAnyAttendee} >

                  {canRemoveAnyAttendee ? (
                    <Confirm
                      confirmText="确定，移除该选手"
                      title="确定要移除这位选手吗？"
                      description="如该选手为自主报名，您将无法再为其报名，除非选手再次申请。"
                      triggerButton={(
                        <button className='outline-none select-none'>
                          <PlayerSmallCard player={i} className="relative" />
                        </button>
                      )}
                      confirmAction={() => remove(i)}
                    >
                    </Confirm>
                  ) : (
                    <PlayerSmallCard player={i} className="relative" />
                  )}
                </SortableItem>
              )
            })}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  )
}

