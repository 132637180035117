import React from 'react'
import { Button } from '@/shadcn/components/ui/button'
import { cn } from '@/shadcn/utils'
import { useToggle } from 'react-use'
import { CardBody, CardContainer, CardItem } from "@/shadcn/motion/3d-card"
import { FlipWords } from '@/shadcn/motion/flip-words'
import { motion } from "framer-motion" 
import { LampContainer } from '@/shadcn/motion/lamp-container'
// import { MockNewEvents } from './mock-new-events'
import { Dock, DockIcon } from '@/shadcn/components/magicui/dock'

export const DevPlayground = () => {
  return (
    <main className='flex min-h-screen flex-col p-24 pad:p-12 phone:p-4'>
      <div className="relative">
      <Dock direction="middle">
        <DockIcon>
          a
        </DockIcon>
        <DockIcon>
          b
        </DockIcon>
        <DockIcon>
          c
        </DockIcon>
        <DockIcon>
          d
        </DockIcon>
      </Dock>
    </div>


      <div className='hidden'>
        {/* <MockNewEvents /> */}

        <svg viewBox="0 0 300 300" >
          <path id="pt" d="M90 75a60 60 0 1 0 120 0a60 60 0 1 0 -120 0z" fill="none" stroke="red"/>
          <text>
            <textPath href="#pt" className='fill-foreground'>
              aaaaabbbbbcccccddddd
            </textPath>
          </text>
        </svg>  

        <FlipWords words={["Harder", "Better", "Faster", "Stronger"]} />
        <Stack />
        <RingRender />
        <ThreeDCardDemo />
        <LightCard />
      </div>
    </main>
  )
}

function Stack() {
  const [stack, toggle] = useToggle(false)
  return (
    <div>
      <Button className="mb-10" onClick={toggle}>toggle</Button>
      <div className={cn("", {
        "stack-open": stack,
        "stack-close": stack === false,
      })}>
        <div className="bg-blue-300 h-24 w-32 flex items-center justify-center rounded">1</div>
        <div className="bg-green-200 h-24 w-32 flex items-center justify-center rounded">2</div>
        <div className="bg-yellow-200 h-24 w-32 flex items-center justify-center rounded">3</div>
        <div className="bg-red-200 h-24 w-32 flex items-center justify-center rounded">4</div>
        <div className="bg-purple-200 h-24 w-32 flex items-center justify-center rounded">5</div>
      </div>
    </div>
  )
}

function ThreeDCardDemo() {
  return (
    <CardContainer className="inter-var">
      <CardBody className="bg-gray-50 relative group/card  dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] dark:bg-black dark:border-white/[0.2] border-black/[0.1] w-auto sm:w-[30rem] h-auto rounded-xl p-6 border  ">
        <CardItem
          translateZ="50"
          className="text-xl font-bold text-neutral-600 dark:text-white"
        >
          Make things float in air
        </CardItem>
        <CardItem
          as="p"
          translateZ="60"
          className="text-neutral-500 text-sm max-w-sm mt-2 dark:text-neutral-300"
        >
          Hover over this card to unleash the power of CSS perspective
        </CardItem>
        <CardItem
          translateZ="100"
          rotateX={20}
          rotateZ={-10}
          className="w-full mt-4"
        >
          <div className='h-60 w-full bg-zinc-300 rounded-xl group-hover/card:shadow-xl'></div>
        </CardItem>
        <div className="flex justify-between items-center mt-20">
          <CardItem
            translateZ={20}
            translateX={-40}
            as="button"
            className="px-4 py-2 rounded-xl text-xs font-normal dark:text-white"
          >
            Try now →
          </CardItem>
          <CardItem
            translateZ={20}
            translateX={40}
            as="button"
            className="px-4 py-2 rounded-xl bg-black dark:bg-white dark:text-black text-white text-xs font-bold"
          >
            Sign up
          </CardItem>
        </div>
      </CardBody>
    </CardContainer>
  );
}

function RingRender() {
  let texts = new Array(12).fill("a")
  texts = texts.map((_, index) => {
    let angle = index / texts.length
    let rotate = `calc(180deg * ${angle})`
    let reverseRotate = `calc(-180deg * ${angle})`
    return { transform: `rotate(${rotate})`, reverse: `rotate(${reverseRotate})` }
  })

  let pinks = new Array(12).fill("pink")
  pinks = pinks.map((_, index) => {
    let angle = index / pinks.length
    let rotate = `calc(360deg * ${angle})`
    let reverseRotate = `calc(-360deg * ${angle})`
    return { transform: `rotate(${rotate})`, reverse: `rotate(${reverseRotate})` }
  })


  let blues = new Array(18).fill("blue").map((_, i) => i)
  blues = blues.map((_, index) => {
    let angle = index / blues.length
    let rotate = `calc(360deg * ${angle})`
    let reverseRotate = `calc(-360deg * ${angle})`
    return { transform: `rotate(${rotate})`, reverse: `rotate(${reverseRotate})` }
  })

  return (
    <div>
      <div className="ring-graph w-[120px] h-[120px] bg-pink-50 opacity-80 rounded-full">
        {texts.map((i, index) => {
          return (
            <div className="element" key={`pink_${index}`}
              style={{ transform: i.transform, left: `calc(50% - 60px)` }}>
              <div
                style={{ transform: i.reverse }}
                className='bg-green-300 rounded grid place-content-center transform-none'>
                <span>{index + 1}</span>
              </div>
            </div>
          )
        })}
      </div>

      <div className="ring-graph hidden w-[960px] h-[960px] bg-pink-50 opacity-80 rounded-full">
        {pinks.map((i, index) => {
          return (
            <div className="element w-[120px]" key={`pink_${index}`}
              style={{ transform: i.transform, left: `calc(50% - 60px)` }}>
              <div
                style={{ transform: i.reverse }}
                className='h-[120px] w-full bg-pink-300 rounded grid place-content-center transform-none'>
                <span>{index + 1}</span>
              </div>
            </div>
          )
        })}
      </div>
      
      <div className="ring-graph hidden w-[880px] h-[880px] bg-blue-50 opacity-80 rounded-full">
        {blues.map((i, index) => {
          return (
            <div className="element w-[240px]" key={`blue_${index}`}
              style={{ transform: i.transform, left: `calc(50% - 120px)` }}>
              <div
                // style={{ transform: i.reverse }}
                className='h-[60px] w-full bg-blue-300 rounded grid place-content-center transform-none'>
                <span>{index + 1}</span>
              </div>
            </div>
          )
        })}
      </div>

    </div> 
  )
}

function LightCard(){
  return (
    <LampContainer>
      <motion.h1
        initial={{ opacity: 0.5, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          delay: 0.3,
          duration: 0.8,
          ease: "easeInOut",
        }}
        className="mt-8 bg-gradient-to-br from-slate-300 to-slate-500 py-4 bg-clip-text text-center text-4xl font-medium tracking-tight text-transparent md:text-7xl"
      >
        Build lamps <br /> the right way
      </motion.h1>
    </LampContainer>
  )

} 

// 纯靠业务计算的方式

// let event = {
// 	id: "e1",
// 	eventName: "name",
// 	ruleTemplateKey: "ftg-team-1", // 以后最好一个 key 自带一个 settings
// 	teams: ["teamId a", "teamId b"],

//  	// 针对 event 规则的单独设置，通常是对 ruleTemplateKey 的补充或自定义
// 	settings: {
//    rankingMethod: "", // 整个 Event 确定胜者的依据（双败单败按淘汰 round 排名，瑞士按得分排名，街机厅组队赛按照）
// 		scoringMethod: "", // 单个 match 评判胜负的依据，不一定适合放在这里（分多胜、分少胜、时间多胜、时间少胜）
// 		teamCoins: 10, // 初始分数，在这个规则下，解读为“币”
// 	},
// }

// let teams = [
// 	{ id: "teamId a", players: [] },
// 	{ id: "teamId b", players: [] },
// ]

// let matches = [
// 	{ 
// 		id: "", 
// 		scoreboard: [{ playerId: "", value: "2" }, { playerId: "", value: "1" }],
// 		teamProperties: { number: 0 },
// 	},
// 	{ 
// 		id: "", 
// 		scoreboard: [{ playerId: "", value: "1" }, { playerId: "", value: "2" }],
// 		teamProperties: { number: 1, },
// 	},
// 	{ 
// 		id: "", 
// 		scoreboard: [{ playerId: "", value: "0" }, { playerId: "", value: "2" }],
// 		teamProperties: { number: 2, },
// 	},
// ]

// const getResult = () => {
// 		let initScore = 10
// 		result = teams.map(i => {
// 			i.winCount = matches.map( () => {} )
// 			i.loseCount = matches.map( () => {} )
// 			i.score = initScore - i.loseCount
// 		})
// }

// Q: 选手如果在两边都存在，可能导致无法判断这一场的胜负归于哪一队
// A: 一个选手不能同时在两个 players 中

// Q: 选手可能同时存在两支队伍，导致冲突
// A: players 是被挑选出来的选手，而不是直接读取 team 中的所有选手，需要主办方手动选择

// Q: 如果要内部训练，同一支队伍要挑选两拨人出来对战，如何解决
// A: teams 在设计上不允许重复，这种场景可以通过新建两只队伍来解决，前端允许从老队伍中继承选手

// Q: 如何判断胜负？
// A: 静态 matches 数据中不体现胜负，如果需要胜负信息，需要计算得出

// Q: 如何允许主办方自定义资源数量
// A: 在 event.settings.teamCoins 字段定义

// Q: 如何允许主办方或队伍自行分配资源，例如分到每个选手头上一个分数？
// A: 靠人为控制吧，界面上可以显示人员的胜负关系、次数

// Q: 如何限制一名选手的胜利、失败、上场次数，这个应该允许主办方自定义？
// Q: 如果想要复合类型的资源，而不是只是一个单纯的分数，这个 teamCoins 似乎不合用？
// A: 核心原则上通过 rule key 控制一个规则的生效范围，这样就算规则设计和程序有问题也不会影响太多

// Q: 
// A: 


// 从一场单纯的篮球比赛出发
// const BasketballMatch = {
// 	// 主分记录数据，这个只有结果记录
// 	scoreboard: [
// 		{ team: "芝加哥公牛", value: "102" },
// 		{ team: "洛杉矶湖人", value: "96" },
// 	],

// 	// 不是主分数据，但是也值得记录的场上数据
// 	statistics: {
// 		mvp: player,
// 		svp: player,
// 		attendees: [
// 			{ playerNumber: "8", rebound: "10", playingTime: "90:00", distanceCovered: "32km" },
// 			{ playerNumber: "24", rebound: "10", playingTime: "90:00", distanceCovered: "32km" },
// 		],
// 	},

// 	// 静态的，基本不改的数据
// 	basketballProperties: {
		
// 	}


// }