import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shadcn/components/ui/form'
import { Slider } from '@/shadcn/components/ui/slider'

export const FormGraphMockSlider = ({ form }) => {
  return (
    <FormField
    control={form.control}
    name="playersCount"
    render={({ field }) => (
      <FormItem className="">
        <FormLabel>预期选手数量({field.value})</FormLabel>
        <FormControl>
          <Slider
            onChange={field.onChange}
            defaultValue={[4]}
            min={4}
            max={64}
            step={1} />
        </FormControl>
        <FormMessage />
      </FormItem>
    )}
  />
  )
}
