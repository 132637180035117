// 为了生成连接线而出的纯函数
const router_right_left = (props) => {
  let { mode, start, end, key, xDistance, spacer, roundSize } = props
  let { yGapCenter1, yGapCenter2 } = props
  let { endTop, startBottom, startTop, endBottom } = props
  let halfSpacer = spacer * 0.5

  let mostBottom = Math.max(
    start.element.y + start.element.h,
    end.element.y + end.element.h
  )
  let mostRight = Math.max(start.x, end.x + end.element.w)
  let mostLeft = Math.min(start.x - start.element.w, end.x) // 因为 start 在节点的最右边，因此要减去节点的宽度

  // 特殊情况（只考虑 start 在 end 右边的情况，因为反之的那种情况不必考虑）
  if (start.x > end.x) {
    let gap1 = endTop - startBottom
    if (end.y > start.y && gap1 < 40) {
      key = `(${props.xk})_(${props.yk})_y_close_${mode}`
    }
    let gap2 = startTop - endBottom
    if (start.y > end.y && gap2 < 40) {
      key = `(${props.xk})_(${props.yk})_y_close_${mode}`
    }
  }
  let mapper = {
    "(end.x_>_start.x)_(end.y_>_start.y)_straight": [
      `L${start.x + xDistance / 2} ${start.y}`,
      `L${start.x + xDistance / 2} ${end.y}`,
    ],
    "(end.x_>_start.x)_(start.y_>_end.y)_straight": [
      `L${start.x + xDistance / 2} ${start.y}`,
      `L${start.x + xDistance / 2} ${end.y}`,
    ],
    "(start.x_>_end.x)_(start.y_>_end.y)_straight": [
      `L${start.x + spacer} ${start.y}`,
      `L${start.x + spacer} ${yGapCenter2}`,
      `L${end.x - spacer} ${yGapCenter2}`,
      `L${end.x - spacer} ${end.y}`,
    ],
    "(start.x_>_end.x)_(end.y_>_start.y)_straight": [
      `L${start.x + spacer} ${start.y}`,
      `L${start.x + spacer} ${yGapCenter1}`,
      `L${end.x - spacer} ${yGapCenter1}`,
      `L${end.x - spacer} ${end.y}`,
    ],
    "(start.x_>_end.x)_(end.y_>_start.y)_y_close_straight": [
      `L${mostRight + spacer} ${start.y}`,
      `L${mostRight + spacer} ${mostBottom + spacer}`,
      `L${end.x - spacer} ${mostBottom + spacer}`,
      `L${end.x - spacer} ${end.y}`,
    ],
    "(start.x_>_end.x)_(start.y_>_end.y)_y_close_straight": [
      `L${start.x + spacer} ${start.y}`,
      `L${start.x + spacer} ${mostBottom + spacer}`,
      `L${mostLeft - spacer} ${mostBottom + spacer}`,
      `L${mostLeft - spacer} ${end.y}`,
    ],

    "(end.x_>_start.x)_(end.y_>_start.y)_round": [
      genRoundPath("right-bottom", { H: start.x + xDistance / 2 }, roundSize),
      genRoundPath("bottom-right", { V: end.y - roundSize }, roundSize),
    ],
    "(end.x_>_start.x)_(start.y_>_end.y)_round": [
      genRoundPath("right-top", { H: start.x + xDistance / 2 }, roundSize),
      genRoundPath("top-right", { V: end.y + roundSize }, roundSize),
    ],
    "(start.x_>_end.x)_(start.y_>_end.y)_round": [
      genRoundPath("right-top", { H: start.x + spacer }),
      genRoundPath("top-left", { V: yGapCenter2 + spacer }), // 这里要取空隙的一半位置
      genRoundPath("left-top", { H: end.x - spacer }),
      genRoundPath("top-right", { V: end.y + halfSpacer }),
    ],
    "(start.x_>_end.x)_(end.y_>_start.y)_round": [
      genRoundPath("right-bottom", { H: start.x + spacer }),
      genRoundPath("bottom-left", { V: yGapCenter1 - halfSpacer }), // 这里要取空隙的一半位置
      genRoundPath("left-bottom", { H: end.x - spacer }),
      genRoundPath("bottom-right", { V: end.y - halfSpacer }),
    ],
    "(start.x_>_end.x)_(end.y_>_start.y)_y_close_round": [
      genRoundPath("right-bottom", { H: mostRight + spacer }),
      genRoundPath("bottom-left", { V: mostBottom + halfSpacer }),
      genRoundPath("left-top", { H: end.x - spacer }),
      genRoundPath("top-right", { V: end.y + halfSpacer }),
    ],
    "(start.x_>_end.x)_(start.y_>_end.y)_y_close_round": [
      genRoundPath("right-bottom", { H: start.x + spacer }),
      genRoundPath("bottom-left", { V: mostBottom + halfSpacer }),
      genRoundPath("left-top", { H: mostLeft - spacer }),
      genRoundPath("top-right", { V: end.y + halfSpacer }),
    ],
  }

  return mapper[key]
}

const genRoundPath = (direction, { V, H }, rs = 20) => {
  // 按如下标记
  //  M 起点
  //  VH 表示横向或者竖直方向的直线
  //  A 表示弧线 (A x半径(20),y半径(20) x旋转度数(0) 优弧劣弧(01) 弧线方向(01) x半径(20) y半径(20))
  // `M0,0 V${end.y - rs} a${rs},${rs} 0 0 0 ${rs},${rs} H${end.x}`
  // 一个直角的圆弧可以被总结成如下的 8 种情形：
  let mapper = {
    "bottom-right": `V${V} a${rs},${rs} 0 0 0 ${rs},${rs}`,
    "bottom-left": `V${V} a${rs},${rs} 0 0 1 ${-rs},${rs}`,
    "top-right": `V${V} a${rs},${rs} 0 0 1 ${rs},${-rs}`,
    "top-left": `V${V - rs} a${rs},${rs} 0 0 0 ${-rs},${-rs}`,
    "left-top": `H${H + rs} a${rs},${rs} 0 0 1 ${-rs},${-rs}`,
    "left-bottom": `H${H + rs} a${rs},${rs} 0 0 0 ${-rs},${rs}`,
    "right-top": `H${H - rs} a${rs},${rs} 0 0 0 ${rs},${-rs}`,
    "right-bottom": `H${H - rs} a${rs},${rs} 0 0 1 ${rs},${rs}`,
  }
  return mapper[direction]
}

const routerMap = ({ start, end, mode }) => {
  let props = { start, end, mode }
  // let mode = "round" // 'round' | 'straight'
  // let round = 20 // 转弯圆角大小（基础值）
  props.round = 5
  props.spacer = 40 // 呼吸空间，通常预留这个空间来作为转弯或间隔
  props.halfSpacer = props.spacer * 0.5

  // 应该放弃 abs 吗？
  let xDistance = Math.abs(start.x - end.x)
  let yDistance = Math.abs(start.y - end.y)
  props.xRoundSize = xDistance / 2 > props.round ? props.round : xDistance / 2
  props.yRoundSize = yDistance / 2 > props.round ? props.round : yDistance / 2
  props.xDistance = xDistance
  props.yDistance = yDistance
  props.roundSize = Math.min(props.xRoundSize, props.yRoundSize) // 时机运用的转弯圆角大小，以 20 为最大值，有可能缩小

  props.xk = end.x >= start.x ? "end.x_>_start.x" : "start.x_>_end.x"
  props.yk = end.y >= start.y ? "end.y_>_start.y" : "start.y_>_end.y"
  props.key = `(${props.xk})_(${props.yk})_${mode}`

  // element 的 xy 指的是左上角，因此不必考虑落点的比例问题
  props.startTop = start.element.y
  props.startBottom = start.element.y + start.element.h
  props.endTop = end.element.y
  props.endBottom = end.element.y + end.element.h
  props.yGapCenter1 = (props.endTop + props.startBottom) / 2
  props.yGapCenter2 = (props.startTop + props.endBottom) / 2

  props.startRight = start.element.x + start.element.w
  props.startLeft = start.element.x
  props.endRight = end.element.x + end.element.w
  props.endLeft = end.element.x
  props.xGapCenter1 = (props.endLeft + props.startRight) / 2
  props.xGapCenter2 = (props.startLeft + props.endRight) / 2

  return router_right_left(props)
  // 分流到 16 个函数里单独处理此事，统一用 props 将所有参数传下去
  // let portKey = `${getPortDirection(start.port)}_${getPortDirection(end.port)}`
  // switch (portKey) {
  //   case "right_left":
  //     return router_right_left(props)
  //   default:
  //     // console.log("这个方向功能暂未实现:", portKey)
  //     return []
  // }
}

// 生成直角路径（可选 mode 为圆角）
const genRightAnglePath = (start, end, mode) => {
  let pathArray = []
  // 画笔先移动到起点
  pathArray.push(`M${start.x} ${start.y}`) // 起点总是固定的
  // 距离过近的防御
  // if (xDistance < 140 || yDistance < 140) {
  //   pathArray.push(`L${end.x} ${end.y}`) // 提前结束，因为可能出错
  //   return pathArray.join(" ")
  // }
  // 原因是 start.port 原本是 'right',
  // 现在是对象，所以要用 start.port.x === 'right'
  pathArray.push(routerMap({ start, end, mode: mode }))
  pathArray.push(`L${end.x} ${end.y}`) // 终点总是固定的
  return pathArray.join(" ")
}

// 将 port 关键字[字符串] 映射为坐标数组
const portMapping = (key) => {
  let map = {
    center: { x: "center", y: "center" },
    top: { x: "center", y: "top" },
    bottom: { x: "center", y: "bottom" },
    left: { x: "left", y: "center" },
    right: { x: "right", y: "center" },
    "top-left": { x: "left", y: "top" },
    "top-right": { x: "right", y: "top" },
    "bottom-left": { x: "left", y: "bottom" },
    "bottom-right": { x: "right", y: "bottom" },
  }
  if (map[key]) {
    return map[key]
  }
  return key
}

const getPositionByKeywords = (portKeywords, view) => {
  let { position, size } = view
  let map = {
    x_center: { x: position.x + size.w / 2 },
    x_left: { x: position.x },
    x_right: { x: position.x + size.w },

    y_center: { y: position.y + size.h / 2 },
    y_top: { y: position.y },
    y_bottom: { y: position.y + size.h },
  }
  let result = {
    x: position.x + portKeywords.x,
    y: position.y + portKeywords.y, // 如果是常量，那么这里直接赋值
    ...map[`x_${portKeywords.x}`],
    ...map[`y_${portKeywords.y}`], // 如果是关键字，那么在后面这段被替换掉
  }
  return result
}

const getPositionByDot = (dot) => {
  // port: 方位常量或 {x, y} 对象，xy 也可以是方位常量
  let { port, element } = dot
  let portKeywords = port
  if (typeof port === "string") {
    portKeywords = portMapping(port) // 字符串的话转换为 kws 对象
  }
  if (element?.view) {
    return {
      port: port,
      element: {
        x: element.view.position.x,
        y: element.view.position.y,
        w: element.view.size.w,
        h: element.view.size.h,
      },
      ...getPositionByKeywords(portKeywords, element.view),
    }
  }
  // 暂不考虑没有 view 的情况，虽然这个情况在 connect 的时候肯定会出现
  return {
    x: dot.x,
    y: dot.y,
    port: port,
  }
}

export const genLinkLineContainer = (from, to, mode = "rightAngle") => {
  // 出一个绘制 svg 所需的数据
  let start = getPositionByDot(from)
  let end = getPositionByDot(to)

  let se = start?.element || { x: 0, y: 0, w: 0, h: 0 }
  let ee = end?.element || { x: 0, y: 0, w: 0, h: 0 }
  let padding = 60

  let container = {
    x: Math.min(se.x, ee.x) - padding,
    y: Math.min(se.y, ee.y) - padding,
  }
  container.w = Math.max(se.x + se.w, ee.x + ee.w) - container.x + padding
  container.h = Math.max(se.y + se.h, ee.y + ee.h) - container.y + padding

  // 将 canvas 坐标转换为相对于 container （SVG容器）的坐标
  // 既然是转换，那就从 start end 的 xy 直接减去 container 的坐标数值
  let lineStart = {
    x: start.x - container.x,
    y: start.y - container.y,
    element: {
      x: se.x - container.x,
      y: se.y - container.y,
      w: se.w,
      h: se.h,
    },
    port: from.port,
  }
  let lineEnd = {
    x: end.x - container.x,
    y: end.y - container.y,
    element: {
      x: ee.x - container.x,
      y: ee.y - container.y,
      w: ee.w,
      h: ee.h,
    },

    port: to.port,
  }

  // gen lines
  let dPath = ""
  let rightAnglePath = ""

  if (mode === "line") {
    // dPath = genLine(lineStart, lineEnd)
    // rightAnglePath = genLine(lineStart, lineEnd)
  } else if (mode === "curve") {
    // dPath = genPath(lineStart, lineEnd)
    // rightAnglePath = genPath(lineStart, lineEnd)
  } else if (mode === "rightAngle") {
    // dPath = genRightAnglePath(lineStart, lineEnd, "round")
    rightAnglePath = genRightAnglePath(lineStart, lineEnd, "straight")
    // rightAnglePath = genRightAnglePath(lineStart, lineEnd, "round")
  }

  return {
    // container 坐标参考系为 canvas
    x: container.x,
    y: container.y,
    width: container.w,
    height: container.h,

    // line 坐标参考系为 container
    lineStart,
    lineEnd,
    dPath,
    rightAnglePath,
  }
}

