import { double8, double16, double32, double64, double4 } from "@/utils/static-gen-match"
import { single4, single8, single16, single32, single64 } from "@/utils/static-gen-match"
// import { shuffle } from "lodash"
import { post } from "@/utils/laf-db"
import { staticLoad } from "@/utils/app-init"
import { Player } from "./player"
import { RULE_TEMPLATE_KEY } from "@/utils/constants"
import { matchesSetMap } from "@/pages/events/create/create-set-page"

const findPowerOf2 = (n) => {
  // if n < 8 => return 8

  if (n < 1) {
    return "ERROR"
  } else if (n <= 4) {
    return 4
  } else if (n <= 8) {
    return 8
  } else if (n <= 16) {
    return 16
  } else if (n <= 32) {
    return 32
  } else if (n <= 64) {
    return 64
  }
  // else if (n <= 128) {
  //   return 128
  // } else if (n <= 256) {
  //   return 256 // 最多支持 256 位选手的赛事
  // }

}

const generateMatch = ({ eventId, tournamentProperties }) => {
  return {
    belongingEventId: eventId,
    type: "tournament",
    tournamentProperties: tournamentProperties,
    name: "",
    stage: "in progress",
    scoreboard: [], // 下一个函数会自动添加
    note: "",
    date: "",
  }
}

export const gameKeys = {
  "sf6": {
    label: "Street Fighter 6", posters: [
      "https://oss.laf.run/cd6zkd-user-upload-images/posts/sf6-1.png",
      "https://oss.laf.run/cd6zkd-user-upload-images/posts/sf6-2.png",
      "https://oss.laf.run/cd6zkd-user-upload-images/posts/sf6-3.jpg",
    ]
  },
  "sf5": { label: "Street Fighter 5", posters: [] },
  "smash": { label: "Super Smash Bros. Ultimate", posters: [] },
  "kof15": { label: "The King of Fighters 15", posters: [] },
  "kof14": { label: "The King of Fighters 14", posters: [] },
}

export const stageMapper = {
  "1-REGISTRATION": { label: "报名阶段", index: 0 },
  "2-CONFIRM-SEAT": { label: "确认席位", index: 1 },
  "3-INPROGRESS": { label: "比赛中", index: 2 },
  "4-FINISHED": { label: "已结束", index: 3 },
}

// 给前端用的 Model
export class GameEvent {

  constructor(json) {
    if (json === undefined) return

    // 先读取
    const staticData = staticLoad()
    this.dbModel = json
    this.owner = json.owner
    this.id = json._id
    this.name = json?.name || '<Data Error, No Name>'
    this.description = json?.description || ""
    // this.compose = json?.compose || "" // 性质，个人赛还是团队赛，但是不确定要不要放在这里。
    this.poster = json.poster
    this.stage = json.stage
    this.stageLabel = stageMapper[json.stage]?.label || '<Data Error, No Stage>'
    this.ruleTemplateKey = json.ruleTemplateKey || '<Data Error, No Rule Template Key>'
    this.rulesText = staticData.rules.find(i => i.key === json.ruleTemplateKey)?.title || '<Data Error, No Rule Text>'
    this.gameKey = json.gameKey
    this.gameName = gameKeys[json.gameKey]?.label || '<Data Error, No Game name>'
    this.attendeeIds = json.attendeeIds || [] // db 层只有 id，this.attendees 是外面手动加入的

    // json.attendees 可能是 null, 可能是由于被删除的历史原因
    this.attendees = json.attendees?.filter(i => i !== null).map?.(i => new Player(i)) || []
    this.visibility = json.visibility // 后端控制的可见性共三种 hidden, private, public
    this.registrationPage = json.registrationPage || false // 开放注册界面
    this.organizer = json.organizer || {}

    let count = json?.attendeeIds?.length
    if (count > 0) {
      this.registrationText = `${count} players registered`
    } else {
      this.registrationText = `No player registered`
    }
  }

  // 单败赛
  genSingleEliminationMatch = (seatsCount) => {
    // 有 8 个选手，如何生成比赛？
    let result = []
    if (seatsCount === 4) {
      result = single4.map(i => generateMatch({ eventId: this.id, tournamentProperties: i }))
    } else if (seatsCount === 8) {
      result = single8.map(i => generateMatch({ eventId: this.id, tournamentProperties: i }))
    } else if (seatsCount === 16) {
      result = single16.map(i => generateMatch({ eventId: this.id, tournamentProperties: i }))
    } else if (seatsCount === 32) {
      result = single32.map(i => generateMatch({ eventId: this.id, tournamentProperties: i }))
    } else if (seatsCount === 64) {
      result = single64.map(i => generateMatch({ eventId: this.id, tournamentProperties: i }))
    }
    return result
  }

  // 双败赛
  genDoubleEliminationMatch = (seatsCount) => {
    // 理想方案，写代码正确合理地生成比赛轮次
    // 现在方案，不写代码，直接近似硬编码，因为情况很少，只有 8、16、32、64、128、256 几种情况，先做到 64 吧
    let result = []
    if (seatsCount === 4) {
      result = double4.map(i => generateMatch({ eventId: this.id, tournamentProperties: i }))
    } else if (seatsCount === 8) {
      result = double8.map(i => generateMatch({ eventId: this.id, tournamentProperties: i }))
      // result = double8_preset1.map(i => generateMatch({ eventId: this.id, tournamentProperties: i }))
    } else if (seatsCount === 16) {
      result = double16.map(i => generateMatch({ eventId: this.id, tournamentProperties: i }))
    } else if (seatsCount === 32) {
      result = double32.map(i => generateMatch({ eventId: this.id, tournamentProperties: i }))
    } else if (seatsCount === 64) {
      result = double64.map(i => generateMatch({ eventId: this.id, tournamentProperties: i }))
    }
    return result
  }

  // 根据注册选手数量，生成比赛轮次(2 的 n 次幂)
  genMatchesByPlayers = (ruleType) => {
    let playersCount = this.attendeeIds.length
    let seatsCount = findPowerOf2(playersCount)
    let matches = []
    if (ruleType === "single") {
      matches = this.genSingleEliminationMatch(seatsCount)
    } else if (ruleType === "double") {
      matches = this.genDoubleEliminationMatch(seatsCount)
    }
    return matches
  }

  // 为 matches 分配当前 events 下的注册选手
  assignPlayers = (matches) => {
    // 随机分配选手到静态的 matches 中 (修改这个 matches 的数据)
    // 读取所有已经分配的选手，将其分配到第一轮的胜者组中
    let pls = this.attendees
    // if (method === "random") {
    //   pls = shuffle(pls)
    // }

    // 次序分配
    let plsIndex = 0
    matches.forEach((match) => {
      let ma = match.tournamentProperties
      if (ma.round === 1 && ma.group === "win") {
        let p1 = pls[plsIndex]
        let p2 = pls[plsIndex + 1]
        if (p1?.id) {
          match.scoreboard.push({ label: "", value: "0", playerId: p1.id })
        }
        if (p2?.id) {
          match.scoreboard.push({ label: "", value: "0", playerId: p2.id })
        }
        plsIndex += 2
      }
    })

  }

  // 删除自身所有 matches
  removeMatches = async () => {
    return await post('/matches/delete-by-event', { eventId: this.id })
  }

  // 删除瑞士轮中指定轮次的 matches
  removeSwissMatchesByRound = async (round) => {
    return await post('/matches/delete-by-event-round', {
      eventId: this.id,
      round: round,
    })
  }

  // 删除自身
  removeSelf = async () => {
    return await post('/events/delete', { eventId: this.id })
  }

  // 瑞士轮专用：生成下一轮的 matches
  genSwissNextRoundMatches = async () => {
    return await post("/matches/add-swiss-round", { eventId: this.id })
  }

  randomAttendees = async () => {
    return await post("/events/random-attendees", { eventId: this.id })
  }


  verifyMatchGenerate = () => {
    if (this.attendeeIds.length === 0) {
      return { result: false, message: "没有选手，无法开始比赛" }
    }
    if (this.stage !== "1-REGISTRATION") {
      return { result: false, message: "比赛已不在报名阶段。" }
    }
    if (this.ruleTemplateKey === RULE_TEMPLATE_KEY.to1 && this.attendeeIds.length < 4) {
      return { result: false, message: "单败赛至少需要 4 名选手参赛" }
    }
    if (this.ruleTemplateKey === RULE_TEMPLATE_KEY.to2 && this.attendeeIds.length < 4) {
      return { result: false, message: "双败赛至少需要 4 名选手参赛" }
    }
    if (this.ruleTemplateKey === RULE_TEMPLATE_KEY.sw1 && this.attendeeIds.length < 5) {
      return { result: false, message: "瑞士轮至少需要 5 名选手参赛" }
    }
    if (this.attendeeIds.length > 64 && (this.ruleTemplateKey === RULE_TEMPLATE_KEY.to2 || this.ruleTemplateKey === RULE_TEMPLATE_KEY.to1)) {
      return { result: false, message: "单场淘汰赛支持最多 64 名选手" }
    }
    return { result: true, message: "" }
  }

  // 生成 matches, 绑定在这个 Event 名下
  generateMatchesOlder = async () => {
    // Todo 检查本 Event 是否已经存在 Matches，如果存在，报错。
    let matches = []
    if (this.ruleTemplateKey === RULE_TEMPLATE_KEY.to1) {
      matches = this.genMatchesByPlayers("single")
      this.assignPlayers(matches)
    } else if (this.ruleTemplateKey === RULE_TEMPLATE_KEY.to2) {
      matches = this.genMatchesByPlayers("double")
      this.assignPlayers(matches)
    } else if (this.ruleTemplateKey === RULE_TEMPLATE_KEY.to2_no_champion) {
      matches = this.genMatchesByPlayers("double").slice(0, -2)
      this.assignPlayers(matches)
    } else if (this.ruleTemplateKey === RULE_TEMPLATE_KEY.sw1) {
      // 这里直接调用接口，不需要走后面了
      this.genSwissNextRoundMatches()
      return
    } else {
      console.log("不支持的赛制，无法生成对阵表")
      return
    }
    if (matches.length === 0) {
      console.log("不支持的赛制或规则，无法生成对阵表")
      return
    }
    let body = { matches }
    return await post('/matches/add', body)
  }

  generateMatches = async () => {
    let matches = []
    const playersCount = this.attendeeIds.length
    let loadMatchesSet = null
    if (this.ruleTemplateKey === RULE_TEMPLATE_KEY.to1) {
      loadMatchesSet = matchesSetMap[`single${playersCount}`]
    }
    if (this.ruleTemplateKey === RULE_TEMPLATE_KEY.to2) {
      loadMatchesSet = matchesSetMap[`double${playersCount}`]
    }
    if (this.ruleTemplateKey === RULE_TEMPLATE_KEY.sw1) {
      this.genSwissNextRoundMatches()
      return
    }


    if (loadMatchesSet) {
      const module = await loadMatchesSet()
      matches = module.default.map(i => {
        return {
          belongingEventId: this.id,
          type: "tournament",
          name: "",
          stage: "in progress",
          scoreboard: [], // 后面的步骤添加
          note: "",
          date: "",
          tournamentProperties: i
        }
      })
    }
    // 为双败赛添加选手
    let offset = 0
    matches = matches.map(i => {
      let scoreboard = []
      let { dependents } = i.tournamentProperties
      if (dependents.length === 0) {
        scoreboard = [
          { playerId: this.attendeeIds[offset], value: "0" },
          { playerId: this.attendeeIds[offset + 1], value: "0" },
        ]
        offset += 2
      }
      if (dependents.length === 1) {
        scoreboard = [
          { playerId: '', value: '' },
          { playerId: this.attendeeIds[offset], value: "0" },
        ]
        offset += 1
      }
      i.scoreboard = scoreboard
      return i
    })

    // 单败赛删除败者组，但是直接硬删除好像也不行
    // if(this.ruleTemplateKey === RULE_TEMPLATE_KEY.to1) {
    //   matches = matches.filter(i => i.tournamentProperties.group === "win" && i.tournamentProperties.reset !== true)
    // }


    let body = { matches }
    // console.log("新版本", matches)
    return await post('/matches/add', body)
  }

  setStage = async (stage) => {
    let body = { eventId: this.id, stage }
    return await post('/events/set', body)
  }
}
