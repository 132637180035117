
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shadcn/components/ui/form'
import { Button } from '@/shadcn/components/ui/button'

import { finder, useStaticData } from "@/utils/app-init"
import { Popover, PopoverContent, PopoverTrigger } from "@/shadcn/components/ui/popover"
import { cn } from "@/shadcn/utils"
import { Check, ChevronDown } from "lucide-react"
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "@/shadcn/components/ui/command"

export const FormGamesPicker = ({ form }) => {
  let { games } = useStaticData()

  return (
    <FormField
      control={form.control}
      name="gameKey"
      render={({ field }) => {
        let gameLabel = finder(field.value, games)?.label
        return (
          <FormItem className="">
            <FormLabel>游戏或运动</FormLabel>
            <Popover>
              <PopoverTrigger asChild >
                <FormControl>
                  <Button
                    variant="outline"
                    role="combobox"
                    className={cn(
                      "w-full justify-between",
                      { "text-muted-foreground": !field.value }
                    )}
                  >
                    {field.value ? gameLabel : "Select game"}
                    <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="p-0 w-full" align="start">
                <Command>
                  <CommandInput placeholder="Search game..." />
                  <CommandEmpty>未找到相关的游戏或运动项目</CommandEmpty>
                  <CommandGroup>
                    {games.map(i => (
                      <CommandItem
                        key={i.key}
                        value={i.label}
                        onSelect={() => {
                          let game = finder(i.key, games)
                          form.setValue("gameKey", i.key)
                          form.setValue("poster", game.posters[0] || "")
                        }}
                      >
                        <Check
                          className={cn("mr-2 h-4 w-4",
                            { "opacity-100": i.key === field.value },
                            { "opacity-0": i.key !== field.value },
                          )}
                        />
                        {i.label}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </Command>
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}
