import React from 'react'
import { cn } from '@/shadcn/utils'
import { Button } from '@/shadcn/components/ui/button'
import { MatchSmallCard } from './match-small-card'
import { Confirm } from '@/shadcn/components/_confirm'
import { DndContext } from "@dnd-kit/core"


const attendeesMatchCount = (attendeesCount) => {
  if (attendeesCount < 5) {
    return null
  } else if (attendeesCount <= 8) {
    return 3
  } else if (attendeesCount <= 16) {
    return 4
  } else if (attendeesCount <= 32) {
    return 5
  } else if (attendeesCount <= 64) {
    return 6
  } else if (attendeesCount <= 128) {
    return 7
  } else if (attendeesCount <= 256) {
    return 8
  }
  return null
}

// 传入 matches 数据，返回能被瑞士轮正确渲染的数据：rounds
const useMatchesToSwissRound = (matches) => {
  let rounds = []
  let maxRound = Math.max(...matches.map(i => i.swissProperties?.round))
  for (let i = 1; i <= maxRound; i++) {
    rounds.push({
      round: i,
      matches: matches.filter(m => m.swissProperties?.round === i)
    })
  }
  return rounds
}


export function SwissBoard({ event, reload, matching }) {
  const matches = event?.matchController?.matches
  let rounds = useMatchesToSwissRound(matches)
  let someUnfinished = matches.some(i => i.stage !== "finished")
  let minRoundNumber = attendeesMatchCount(event?.attendeeIds?.length)
  let currentRound = Math.max(...rounds.map(i => i.round)) // 也可以理解为“目前数字最高的轮次”

  const handleSeatChange = (e) => {
    console.log("e", e)
  }
  // 瑞士轮暂时没有其他视图
  return (
    <DndContext onDragEnd={handleSeatChange}>
      <div className='h-full pb-36 overflow-scroll no-scrollbar'>
        {rounds.map(r => {
          let thisRoundMatchesCount = r.matches.length
          let thisRoundRecordedCount = r.matches.filter(i => i.stage === "finished").length
          return (
            <div key={r.round} className='mb-12'>
              <div className='py-1 pl-3 my-4 mx-8 text-muted-foreground bg-secondary rounded flex items-center'>
                <span>第 {r.round} 轮</span>
                {matching && currentRound === r.round && event.stage !== "4-FINISHED" && (
                  <Confirm
                    confirmText="是的，删除本轮对局"
                    title="确定删除本轮对局吗？"
                    description={`本轮共 ${thisRoundMatchesCount} 场比赛记录将被删除，当前标记 ${thisRoundRecordedCount} 场已完赛。`}
                    triggerButton={(
                      <Button variant="ghost" className="ml-auto">
                        删除本轮对局
                      </Button>
                    )}

                    confirmAction={async () => {
                      await event?.removeSwissMatchesByRound?.(r.round)
                      await reload()
                    }}
                  >
                  </Confirm>
                )}
              </div>
              <div className='flex px-8 w-full flex-wrap gap-y-10 gap-x-12'>
                {r.matches.map(i => {
                  return (
                    <div key={i.id}
                      data-match-id={i.id}
                      className={cn("rounded-sm relative")}
                    >
                      <MatchSmallCard
                        stage={event?.stage}
                        match={i}
                        reload={reload}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}

        <div className='flex mt-16 justify-center'>
          {currentRound < minRoundNumber && (
            <Button
              disabled={someUnfinished}
              onClick={async () => {
                await event.genSwissNextRoundMatches()
                await reload()
              }}>
              开启新一轮对阵
            </Button>
          )}
        </div>
      </div>
    </DndContext>
  )
}

