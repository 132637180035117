import { useState } from 'react'
import { usePlayersPickerSource } from '@/hooks/use-players'
import { ToggleGroup, ToggleGroupItem } from '@/shadcn/components/ui/toggle-group'
import { PlayerSmallCard } from './player-card'
import { Command, CommandDialog, CommandInput } from '@/shadcn/components/ui/command'
import { cn } from '@/shadcn/utils'
import { Button } from '@/shadcn/components/ui/button'
import { post } from '@/utils/laf-db'
import { Blend, Trash2 } from 'lucide-react'
import { Textarea } from '@/shadcn/components/ui/textarea'
import { toast } from 'sonner'
import { ContentUnavailable } from '@/shadcn/components/_content-unavailable'
// import { useThrottle } from 'react-use'
// import { Button } from '@/shadcn/components/ui/button'


// 一个表单选择组件，通过 [id, id, id] 来选择选手
// 每个选手通过一个小卡片展示，数据源是 usePlayersByIds
export function PlayersPicker({ value = [], onChange, option }) {
  // 正常选择
  let [search, setSearch] = useState("")

  // 分析相关
  let [showTextAnalyze, setShowTextAnalyze] = useState(false)
  let [clue, setClue] = useState("")

  let { data: players, mutate } = usePlayersPickerSource({ search })

  // 新建选手并选择
  const createPlayerAndPick = async () => {
    await post("/players/add", { players: [{ name: search, color: "Zinc" }] })
    await mutate()
  }

  // 执行分析
  const doTextAnalyze = async () => {
    let related = []
    players.forEach((player) => {
      if (clue.includes(player.name)) {
        let i = player.id
        related.push(i)
      }
    })

    let newValue = [...new Set([...(value || []), ...related])] // 求并集
    onChange(newValue)

    // 收尾和提示
    let addCount = newValue.length - value.length
    if (addCount === 0) {
      toast.message('文本线索未匹配到任何选手，或他们已被选中', { duration: 2000 })
      return
    }
    toast.success(`已根据文本线索匹配选中 ${addCount} 位选手`, { duration: 3000 })
    setShowTextAnalyze(false)
  }

  return (
    <div className='h-fit'>
      <div className="mb-2 flex items-center">
        <div>当前已选中 {value?.length} 位选手</div>
        {option?.cleanable && (
          <Button className="ml-auto" variant="ghost" onClick={() => onChange([])}>
            <Trash2 className='w-4 h-4 mr-1' />
            <span>清空</span>
          </Button>
        )}
      </div>

      <Command className="border h-80 relative">
        <Button onClick={() => setShowTextAnalyze(true)} size="sm" variant="ghost" className="absolute right-1 top-1">
          <Blend className='w-4 h-4 mr-1' />
          <span>自动分析</span>
        </Button>

        <CommandInput placeholder="输入选手名称来查找..." value={search} onValueChange={e => setSearch(e)} />
        <div className={cn('flex-grow p-2 overflow-scroll no-scrollbar')}>
          <ToggleGroup
            value={value}
            onValueChange={(v) => {
              // console.log("onchange", v)
              onChange(v)
            }}
            type="multiple"
            className="grid grid-cols-3">
            {players?.map((player) => {
              return (
                <ToggleGroupItem
                  key={player.id}
                  value={player.id}
                  className="pl-0 justify-start">
                  <PlayerSmallCard className="overflow-hidden" player={player} />
                </ToggleGroupItem>
              )
            })}
          </ToggleGroup>

          {(players?.length === 0 && search !== "") && (
            <div className='h-full w-full flex flex-col items-center justify-center gap-2'>
              <div>没有找到名为“{search}”的选手</div>
              <Button onClick={() => createPlayerAndPick()}>
                新增 “{search}” 选手
              </Button>
            </div>
          )}

          {(players?.length === 0 && search === "") && (
            <ContentUnavailable className="h-full border-none" title="未找到其他选手"
              description="这可能是由于网络错误导致的问题，请重新登录、刷新重试。" />
          )}
        </div>
      </Command>

      <CommandDialog open={showTextAnalyze} onOpenChange={setShowTextAnalyze}>
        <div className='p-4'>
          <div className='pr-8 w-full flex items-center'>
            输入文本（例如群接龙或复制报名表），自动分析并选中名字在其中的选手，仅对已存在于选手列表中的生效。
          </div>
          <Textarea className='w-full h-48 my-8' value={clue} onChange={e => setClue(e.target.value)} />
          <Button className="w-full" onClick={doTextAnalyze}>确认</Button>

        </div>
      </CommandDialog>
    </div>
  )
}