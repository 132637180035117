import { post } from '@/utils/laf-db'
import { cloneDeep } from 'lodash'
import { Player } from './player'
import { RULE_TEMPLATE_KEY } from '@/utils/constants'

export const getWinnerLoserByScore = (oldScoreboard) => {
  // 避免 sort 修改原数组
  let scoreboard = [...oldScoreboard].filter(i => {
    return i.playerId
  })

  // 0. 只有一个选手，且被记分的情况
  if (scoreboard.length === 1 && scoreboard[0].value > 0) {
    return { winner: scoreboard[0], loser: null }
  }

  // 1. score 相等，不需要读比分了
  let scoreEqual = scoreboard.every(i => i.value === scoreboard[0].value)
  if (scoreEqual) {
    return { winner: null, loser: null }
  }

  // 2. 排序，[0]是 winner, [1]是 loser
  let scoreBigger = scoreboard.sort((a, b) => Number(b.value) - Number(a.value))
  let win2 = scoreBigger[0]
  let lose2 = scoreBigger[1]
  return { winner: win2, loser: lose2 }
}

// 拷贝一份 scoreboard，保证长度一定是 2
export const copyScoreboard = (scoreboard) => {
  let newScoreboard = cloneDeep(scoreboard)
  if (newScoreboard.length === 0) {
    newScoreboard = [{}, {}]
  } else if (newScoreboard.length === 1) {
    newScoreboard.push({})
  }
  return newScoreboard
}

// 管理一组 match 的类
export class MatchController {
  constructor(matchData, event) {
    this.event = event
    this.matches = []
    this.markPoints = []
    this.links = []

    if (Array.isArray(matchData)) {
      this.matches = matchData
        .map((i) => new Match(i, this))
        // .sort((a, b) => a.tournamentProperties.number - b.tournamentProperties.number)
      this.setPlayers()
    }
  }

  setPlayers = () => {
    this.matches.forEach((i) => {
      i.scoreboard.forEach((scoreItem) => {
        if (!scoreItem) return
        let p = this.event.attendees.find(a => {
          return a.id === scoreItem.playerId
        })
        if (p) {
          scoreItem.player = p
        }
      })
    })
  }

}

export class Match {
  constructor(json, controller) {
    // meta
    this.controller = controller
    this.owner = json.owner ? json.owner : controller?.event?.owner

    this.rawData = { ...json }
    this.id = json._id
    this.name = json.name
    this.note = json.note
    this.eventId = json.belongingEventId
    this.stage = json.stage // 比赛状态, "in progress" || "finished"
    this.date = json.date ? new Date(json.date) : new Date()

    // 这个信息不一定有的，因为只在双败赛会有 group 和 reset，只在锦标赛会有轮次
    // maybe fold:
    this.tournamentProperties = json.tournamentProperties || {} // object json
    this.swissProperties = json.swissProperties || {} // object json

    // 规则和记分
    this.type = json.type // single or tournament
    this.rule = json.rule // 每一场比赛的基本规则，目前来说单场是 1v1 为主，是有限的枚举
    // console.log("json.scoreboard", json.scoreboard)
    this.scoreboard = json.scoreboard?.map(i => {
      if (i.player) {
        i.player = new Player(i.player)
      }
      return i
    }) || []

    // 位置和附加信息
    this.x = json?.x // logic position
    this.y = json?.y // 
    this.linkIds = json?.linkIds // link to other match

    // number 从 Properties 里取出来的快捷方式
    if(json?.tournamentProperties?.number !== undefined){
      this.number = json?.tournamentProperties?.number
    } else if (json?.swissProperties?.number !== undefined){
      this.number = json?.swissProperties?.number
    }

    // 缓存 players 信息，通过 id 作为key 读取
    // this.cachePlayers = json.cachePlayers || {}
  }

  // 标记单个比赛分数
  markScore = async (newScoreboard) => {
    let result = await post('/matches/set', {
      matches: [{ _id: this.id, scoreboard: newScoreboard }]
    })
    return result
  }

  // 分数加、减，本来通过 player 查找的方式来修改，但是这样无选手的话就做不了，所以改为 index 查找
  moveScore = async ({ value, index }) => {
    let n = Number(value) || 0
    let newScoreboard = this.scoreboard.map((i, idx) => {
      let newScore = { value: i.value, playerId: i?.playerId || "" }
      if (index !== undefined && idx === index) { // 根据 index 查找
        newScore.value = String(Number(i.value) + n)
      }
      //  else if (player?.id && i?.playerId === player?.id) { // 根据 player 查找
      //   newScore.value = String(Number(i.value) + n)
      // }
      return newScore
    })
    return await this.markScore(newScoreboard)
  }

  resetScore = async () => {
    let newScoreboard = copyScoreboard(this.scoreboard)
    newScoreboard.forEach(i => {
      i.value = "0"
    })
    // if (newScoreboard[0].value !== undefined) {
    //   newScoreboard[0].value = "0"
    // }
    // if (newScoreboard[1].value !== undefined) {
    //   newScoreboard[1].value = "0"
    // }
    return await this.markScore(newScoreboard)
  }

  // 修改字段，需要后端同步修改，目前仅支持 stage
  markStage = async (stage) => {
    // if (stage === this.stage) {
    //   console.log("stop")
    //   return
    // }
    let result = await post('/matches/set', {
      matches: [{ _id: this.id, stage }]
    })
    return result
  }

  // 计算对应的选手晋级
  // 这里必须考虑双败制和其他赛制的不同
  playersPromote = async () => {
    let { event } = this.controller
    if (event.ruleTemplateKey === RULE_TEMPLATE_KEY.to1) {
      return this.playersPromoteInSingle()
    }
    // 双败赛
    if (event.ruleTemplateKey === RULE_TEMPLATE_KEY.to2) {
      return this.playersPromoteInDouble()
    }
  }

  playersPromoteInSingle = async () => {
    let { matches } = this.controller
    let { winner, loser } = getWinnerLoserByScore(this.scoreboard)

    // 检查胜者败者
    if (winner === null && loser === null) {
      // Todo 考虑没有 winner 和 loser 的情况，是否要删除对应的下层依赖？
      return
    }
    let tasks = []
    let dwm = matches.find(i => i.tournamentProperties.dependents.includes(`${this.tournamentProperties.number} win`))

    if (dwm) {
      let winIndex = dwm.tournamentProperties.dependents.findIndex(i => i === `${this.tournamentProperties.number} win`)
      // 读取位置的原选手，如果是同一选手，那么不做操作，如果不是，那么替换掉且分数清零
      if (dwm.scoreboard?.playerId !== winner.playerId) {
        let nsb = copyScoreboard(dwm.scoreboard) // 保证长度一定是 2
        nsb[winIndex] = { playerId: winner.playerId, value: "0" }
        tasks.push({ matchId: dwm.id, scoreboard: nsb })
      }
    }

    if (tasks.length > 0) {
      return post('/matches/update-multi-score', { tasks })
    }
  }

  playersPromoteInDouble = async () => {
    // 查看谁依赖这一组内容
    let { matches } = this.controller
    let { winner, loser } = getWinnerLoserByScore(this.scoreboard)

    // 检查这里是否为 reset 的前一轮
    let resetNumber = matches.find(i => i.tournamentProperties.reset === true)?.tournamentProperties?.number
    if (resetNumber > 0 && this.tournamentProperties.number === resetNumber - 1) {
      return this.resetRoundCheck()
    }

    // 检查胜者败者
    if (winner === null && loser === null) {
      // Todo 考虑没有 winner 和 loser 的情况，是否要删除对应的下层依赖？
      return
    }
    let tasks = []

    let dwm = matches.find(i => i.tournamentProperties.dependents.includes(`${this.tournamentProperties.number} win`))
    let dlm = matches.find(i => i.tournamentProperties.dependents.includes(`${this.tournamentProperties.number} lose`))
    // 准备将检查胜者、败者放入指定位置，获取位置

    if (dwm) {
      let winIndex = dwm.tournamentProperties.dependents.findIndex(i => i === `${this.tournamentProperties.number} win`)
      // 读取位置的原选手，如果是同一选手，那么不做操作，如果不是，那么替换掉且分数清零
      if (dwm.scoreboard?.playerId !== winner.playerId) {
        let nsb = copyScoreboard(dwm.scoreboard) // 保证长度一定是 2
        nsb[winIndex] = { playerId: winner.playerId, value: "0" }
        tasks.push({ matchId: dwm.id, scoreboard: nsb })
      }
    }

    if (dlm) {
      let loseIndex = dlm.tournamentProperties.dependents.findIndex(i => i === `${this.tournamentProperties.number} lose`)
      if (dlm.scoreboard?.playerId !== loser?.playerId) {
        let nsb = copyScoreboard(dlm.scoreboard) // 保证长度一定是 2
        nsb[loseIndex] = { playerId: loser.playerId, value: "0" }
        tasks.push({ matchId: dlm.id, scoreboard: nsb })
      }
    }

    if (tasks.length > 0) {
      return post('/matches/update-multi-score', { tasks })
    }
  }

  resetRoundCheck = async () => {
    let { winner, } = getWinnerLoserByScore(this.scoreboard)
    let p2 = this.scoreboard[1]
    let resetMatch = this.controller.matches.find(i => i.tournamentProperties.reset === true)
    if (winner && winner.playerId === p2?.playerId) {
      let nsb = copyScoreboard(this.scoreboard)
      nsb[0].value = "0"
      nsb[1].value = "0"
      return post('/matches/update-multi-score', {
        tasks: [{ matchId: resetMatch.id, scoreboard: nsb }]
      })
    }

    // 其他所有情况，说明 reset 是不必要的，就清空 reset 的选手和分数
    return post('/matches/set', {
      matches: [{ _id: resetMatch.id, scoreboard: [] }]
    })
  }

  removeSelf = () => {
    // 仅限
    if (this.type === "single") {
      return post('/matches/delete', { matchId: this.id })
    }
    console.log("tournament 类型的比赛不允许自行删除")
  }
}