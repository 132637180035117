
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shadcn/components/ui/form'


import { RadioGroup, RadioGroupItem } from '@/shadcn/components/ui/radio-group'

export const FormVisibilityPicker = ({ form }) => {

  return (
    <FormField
      control={form.control}
      name="visibility"
      render={({ field }) => (
        <FormItem>
          <FormLabel>隐私</FormLabel>
          <FormControl>
            <RadioGroup value={field.value} onValueChange={(e) => field.onChange(e)}>
              <FormItem className="flex items-center space-y-0">
                <FormControl>
                  <RadioGroupItem value="public" />
                </FormControl>
                <FormLabel className="pl-2">公开访问，管理员可修改</FormLabel>
              </FormItem>
              <FormItem className="flex items-center space-y-0">
                <FormControl>
                  <RadioGroupItem value="private" />
                </FormControl>
                <FormLabel className="pl-2">私有，仅限管理员可访问</FormLabel>
              </FormItem>
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
