import React from 'react'
import { Button } from '@/shadcn/components/ui/button'
import { HeaderText } from '@/components/header-text'
import { Plus, RotateCcw } from 'lucide-react'
import { Link } from 'react-router-dom'
import { useCurrentUser } from '@/hooks/use-session'
import { cn } from '@/shadcn/utils'
import { useCommunities } from '@/hooks/use-communities'
import { PlayerAvatar } from '@/shadcn/components/_player-avatar'
// import { ContentUnavailable } from '@/shadcn/components/_content-unavailable'

export function CommunityListPage() {
  const { data: account } = useCurrentUser()
  const { data: communities, mutate } = useCommunities()

  return (
    <div>
      <div className="flex items-center mb-8">
        <HeaderText>社区</HeaderText>
        <div className="hidden ml-auto space-x-2">
          <Button variant="outline" size="icon" onClick={mutate} >
            <RotateCcw className='w-4 h-4' />
          </Button>
          {account && (
            <Link to="/community-create">
              <Button variant="outline" size="icon" >
                <Plus className='w-4 h-4' />
              </Button>
            </Link>
          )}
        </div>
      </div>

      <div className={cn("mt-4 space-y-4")}>
        <div>
          {/* <div className='font-bold'>公开社区</div> */}
          <div className='flex flex-col gap-2'>
            {Array.isArray(communities) && communities.map((i, index) => (
              <Link key={`communities_${i.id}`} to={`/community/${i.id}`}>
                <div className='p-2 bg-secondary border-2 border-secondary hover:border-foreground rounded-xl cursor-pointer transition-all'>
                  <div className='text-xl'>{i.name}</div>
                  <div>由 {i.organizer?.displayName} 管理</div>
                  <div className='flex items-center'>
                    <div className='flex'>
                      {i.members.map(player => {
                        return <PlayerAvatar key={player.id} player={player} className='w-8 h-8 bg-primary-foreground -mr-2' />
                      })}
                    </div>
                    <div className='ml-auto text-xs'>Members {i.members.length} / 256</div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>

        {/* <div>
          <div className='font-bold'>我创建的社区</div>
          <ContentUnavailable title="我创建的社区" description="创建社区暂不可用" />
        </div>

        <div>
          <div className='font-bold'>我加入的社区</div>
          <ContentUnavailable title="我加入的社区" description="加入社区暂不可用" />
        </div> */}
      </div>
    </div>
  )
}
