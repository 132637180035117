import * as z from "zod"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shadcn/components/ui/form'
import { Button } from '@/shadcn/components/ui/button'
import { useForm } from 'react-hook-form'
import { zodResolver } from "@hookform/resolvers/zod"
import {  Minus, Plus } from "lucide-react"
import { post } from '@/utils/laf-db'
import { HeaderText } from '@/components/header-text'
import { useNavigate } from "react-router-dom"
import { EventCell } from "./event-page-list"
import { finder, useStaticData } from "@/utils/app-init"
import { PlayerSmallCard } from "@/components/player-card"
import { Modal } from "@/shadcn/components/_modal"
import { PlayersPicker } from "@/components/players-picker"
import { Switch } from "@/shadcn/components/ui/switch"
import { usePlayers } from "@/hooks/use-players"
import { RULE_TEMPLATE_KEY } from "@/utils/constants"
import { FormNameInput } from "./create/form-name-input"
import { FormGamesPicker } from "./create/form-games-picker"
import { FormRulePicker } from "./create/form-rule-picker"
import { FormPosterPicker } from "./create/form-poster-picker"
import { FormDescriptionInput } from "./create/form-description"
import { FormVisibilityPicker } from "./create/form-visibility-picker"

const formSchema = z.object({
  name: z.string().min(2, { message: "Name must be at least 2 characters." }),
  description: z.string(),
  ruleTemplateKey: z.string(),
  gameKey: z.string({ required_error: "Select a game or sport", }),
  visibility: z.string(),
  poster: z.string(),
  // attendees: z.array(z.instanceof(Player)),
  attendeesIds: z.array(z.string()).optional(),
  registrationPage: z.boolean(),
})

export const CreateEventPage = ({ event, scene }) => {
  const navigate = useNavigate()
  let { rules, games } = useStaticData()
  let { data: allPlayers } = usePlayers()

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: event?.name || "",
      description: event?.description || "",
      ruleTemplateKey: event?.ruleTemplateKey || RULE_TEMPLATE_KEY.to1,
      gameKey: event?.gameKey || "sf6",
      visibility: event?.visibility || "public",
      attendeeIds: event?.attendeeIds || [],
      poster: "https://oss.laf.run/cd6zkd-user-upload-images/posts/sf6-1.png",
      registrationPage: false,
    },
  })
  let watch = form.watch()

  const onCreate = async () => {
    let result = await form.trigger()
    if (!result) { return }
    let values = form.getValues()
    let body = {
      name: values.name,
      description: values.description,
      gameKey: values.gameKey,
      ruleTemplateKey: values.ruleTemplateKey,
      visibility: values.visibility,
      poster: values.poster,
      attendeeIds: values.attendeeIds,
      registrationPage: values.visibility === "public" ? values.registrationPage : false,
    }
    let { data } = await post("/events/add", body)
    navigate(`/events/${data}`, { replace: true })
  }

  const playerFinder = (p) => {
    if (allPlayers) {
      return allPlayers.find(i => i.id === p)
    }
  }

  return (
    <div>
      <HeaderText>创建新赛事</HeaderText>
      <Form {...form}>
        <form className="" onSubmit={(e) => { e.preventDefault() }} >
          <div className='flex mt-8 phone:flex-col phone:p-2'>
            <div className="pad:w-36 w-48 text-xl self-start flex shrink-0">基本信息</div>
            <div className='flex-grow phone:mt-4 space-y-8'>
              <FormNameInput form={form} />
              <FormGamesPicker form={form} />
              <FormRulePicker form={form} />
              <FormPosterPicker form={form} />
              <FormDescriptionInput form={form} />
              <FormVisibilityPicker form={form} />
            </div>
          </div>

          <div className='flex mt-16 phone:flex-col phone:p-2'>
            <div className="pad:w-36 w-48 text-xl self-start flex shrink-0">选手</div>
            <div className='flex-grow phone:mt-4'>
              <FormField
                control={form.control}
                name="attendeeIds"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel >
                      <div className="relative">
                        <span>
                          {field.value?.length ? `已有 ${field.value?.length} 名选手报名` : "暂无选手报名"}
                        </span>
                      </div>
                    </FormLabel>
                    <FormControl>
                      <div className='border w-full p-2 rounded-xl'>
                        <div className="flex flex-wrap gap-1 min-h-10">
                          {field.value?.map(i => (
                            <PlayerSmallCard
                              className="bg-secondary group relative"
                              key={i}
                              player={playerFinder(i)}
                              appendRender={(
                                <div className="space-x-1 absolute right-1 z-10">
                                  <Button variant="ring" size="icon" className="rounded-full w-6 h-6 opacity-0 group-hover:opacity-100"
                                    onClick={() => {
                                      // console.log('field.value', field.value)
                                      field.onChange(field.value.filter(j => j !== i))
                                    }}
                                  >
                                    <Minus className="h-3 w-3" />
                                  </Button>
                                </div>
                              )}
                            />
                          ))}
                          <Modal
                            title="为选手报名"
                            triggerButton={(
                              <Button className="" variant="ghost">
                                <Plus className="mr-1 w-4 h-4" />
                                <span>添加选手</span>
                              </Button>
                            )}>
                            <PlayersPicker value={field.value} onChange={field.onChange} option={{ cleanable: true }} />
                          </Modal>
                        </div>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {watch.visibility === "public" && (
                <FormField
                  control={form.control}
                  name="registrationPage"
                  render={({ field }) => (
                    <FormItem className='mt-8'>
                      <FormLabel className="block">
                        提供自主报名界面
                      </FormLabel>
                      <FormControl>
                        <label className="flex items-center">
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                          <span className="ml-2 text-xs text-secondary-foreground select-none">用户可通过访问网址自行注册报名</span>
                        </label>
                      </FormControl>
                    </FormItem>
                  )}
                />
              )}

              <div className="p-4 rounded-lg bg-secondary text-sm mt-4">
                主办方可随时自行调整选手名单，只要赛事尚未开始。
              </div>
            </div>
          </div>
        </form>
      </Form>

      <div className='flex mt-16 phone:flex-col phone:p-2'>
        <div className="pad:w-36 w-48 text-xl self-start flex shrink-0">最终确认</div>
        <div className="w-full">
          <FormField
            control={form.control}
            name=""
            render={() => {
              // let watch = form.watch()
              return (
                <FormItem>
                  <EventCell className="bg-secondary rounded-xl transition-all"
                    event={{
                      name: watch.name,
                      gameName: finder(watch.gameKey, games)?.label,
                      stageLabel: "报名阶段",
                      rulesText: finder(watch.ruleTemplateKey, rules)?.title,
                      description: watch.description,
                      registrationText: `${watch.attendeeIds.length} 位选手已报名`,
                      organizer: { displayName: "你" },
                      poster: watch.poster,
                    }}
                  />
                </FormItem>
              )
            }}
          />

          <Button className="w-full mt-4" onClick={onCreate}>
            创建比赛
          </Button>
        </div>
      </div>
    </div>
  )
}