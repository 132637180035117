
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shadcn/components/ui/form'
import { Button } from '@/shadcn/components/ui/button'
import { finder, useStaticData } from "@/utils/app-init"

export const FormRulePicker = ({ form }) => {
  let { rules } = useStaticData()

  return (
    <FormField
      control={form.control}
      name="ruleTemplateKey"
      render={({ field }) => (
        <FormItem className="space-y-3">
          <FormLabel>规则</FormLabel>
          <FormControl>
            <div className="">
              <div className='grid grid-cols-3 gap-4 phone:grid-cols-none'>
                {rules.map(i => {
                  return (
                    <Button key={i.key}
                      variant={i.key === field.value ? "default" : "secondary"}
                      onClick={() => {
                        form.setValue("ruleTemplateKey", i.key)
                        field.onChange(i.key)
                      }}
                    >
                      {i.title}
                    </Button>
                  )
                })}
              </div>
              <div className="mt-2 text-sm text-secondary-foreground">
                {finder(field.value, rules)?.description}
              </div>
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
