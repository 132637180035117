import React from 'react'
import { createBrowserRouter, } from "react-router-dom"
import { Home } from '@/pages/home/home-page'
import { EventListPage } from '@/pages/events/event-page-list'
import { EventPageDetail } from '@/pages/events/detail/page-detail'
import { NavBar } from '@/components/nav-bar'

import { PlayerListPage } from '@/pages/players/player-page-list'
import { MatchesPageList } from '@/pages/matches/matches-page-list'
import { AccountSettings } from './pages/account/settings'
import { ForgetPasswordPage } from '@/pages/account/forget-password'
import { PostPage } from './posts'
import { PostListPage } from './posts/post-list'
import { CreateEventPage } from './pages/events/create-page'
import { CreateEventSetPage } from './pages/events/create/create-set-page'
import { DevPlayground } from '@/pages/dev-playground/dev-playground'
import { RegistrationPage } from '@/pages/registration/registration-page'
import { CommunityListPage } from './pages/community/community-page-list'
import { CreateCommunity } from './pages/community/create'
import { Community } from './pages/community/community'


const LayoutPage = (props) => {
  return (
    <main>
      {/* 尽量确保 NavBar 是唯一一个，这样 Close 时不会丢失动画 */}
      <NavBar />

      {(props.container === undefined || props.container === "spacer") && (
        <div className='grid min-h-[100dvh] phone:min-h-[calc(100dvh-4rem)] w-full max-w-5xl mx-auto p-24 pad:p-12 phone:p-4'>
          {props.children}
        </div>
      )}
      {props.container === "normal" && (
        <div>{props.children}</div>
      )}
    </main>
  )
}

export const router = createBrowserRouter([
  {
    path: "/",
    element: <LayoutPage container="normal"><Home /></LayoutPage>, // Todo
    children: [],
  },
  {
    path: "/events",
    element: <LayoutPage><EventListPage /></LayoutPage>,
    children: [],
  },
  {
    path: "/events-create",
    element: <LayoutPage><CreateEventPage /></LayoutPage>,
    children: [],
  },
  {
    path: "/events-set-create",
    element: <LayoutPage><CreateEventSetPage /></LayoutPage>,
    children: [],
  },
  {
    path: "/events/:id",
    element: <LayoutPage><EventPageDetail /></LayoutPage>,
    children: [],
  },
  {
    path: "/matches",
    element: <LayoutPage><MatchesPageList /></LayoutPage>,
    children: [],
  },
  // {
  //   path: "/matches/:id",
  //   element: <LayoutPage> <MatchDetail /> </LayoutPage>,
  //   children: [],
  // },
  {
    path: "/communities",
    element: <LayoutPage><CommunityListPage /></LayoutPage>,
    children: [],
  },
  {
    path: "/community/:id",
    element: <LayoutPage><Community /></LayoutPage>,
    children: [],
  },
  {
    path: "/community-create",
    element: <LayoutPage><CreateCommunity /></LayoutPage>,
    children: [],
  },
  {
    path: "/players",
    element: <LayoutPage><PlayerListPage /></LayoutPage>,
    children: [],
  },
  {
    path: "/account/settings",
    element: <LayoutPage> <AccountSettings /> </LayoutPage>,
    children: [],
  },
  {
    path: "/account/forget-password",
    element: <LayoutPage> <ForgetPasswordPage /> </LayoutPage>,
    children: [],
  },
  {
    path: "/registration", // 报名比赛，不是注册账户
    element: <LayoutPage> <RegistrationPage /> </LayoutPage>,
    children: [],
  },
  {
    path: "/docs",
    element: <LayoutPage> <PostListPage /> </LayoutPage>,
    children: [],
  },
  {
    path: "/docs/:slug",
    element: <LayoutPage container="normal"> <PostPage /> </LayoutPage>,
    children: [],
  },
  {
    path: "/dev-playground",
    element: <DevPlayground />,
    children: [],
  },
])


