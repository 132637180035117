import { useContext } from 'react'
import { Avatar, AvatarFallback, AvatarImage } from "@/shadcn/components/ui/avatar"
import { Button } from '@/shadcn/components/ui/button'
import { AccountContext } from '@/components/laf-auth/account'
import { useCurrentUser } from '@/hooks/use-session'
import { useNavigate } from 'react-router-dom'
import { Modal } from '@/shadcn/components/_modal'
import { BasicForm } from './basic-form'
import { DisplayNameForm } from './display-name-form'
import { ResetPasswordForm } from './reset-password-form'
import { toast } from 'sonner'
import { EditIcon } from 'lucide-react'
import { ImageUploaderForm } from '@/components/image-uploader-form'


export const AccountSettings = () => {
  let { data: account, mutate } = useCurrentUser()
  const { logout } = useContext(AccountContext)
  const navigate = useNavigate()

  return (
    <div className='flex flex-col'>
      <div className='flex items-center space-x-2 bg-secondary p-4 rounded-xl'>
        <Modal
          title="修改头像"
          triggerButton={(
            <Avatar className="rounded-full cursor-pointer h-16 w-16 ring-2 ring-background">
              <AvatarImage src={account?.avatar} alt="Avatar" className="object-cover" />
              <AvatarFallback>{account?.avatarFallback}</AvatarFallback>
            </Avatar>
          )}
          render={({ close }) => {
            return (
              <ImageUploaderForm
                uploadedCallback={async (result) => {
                  let { url } = result.data
                  await account.updateUserDisplayField({ avatar: url })
                  await mutate()
                  close()
                }}
                placeholder={(
                  <Avatar className="rounded-full h-24 w-24">
                    <AvatarImage src={account?.avatar} alt="Avatar" className="object-cover" />
                    <AvatarFallback>{account?.avatarFallback}</AvatarFallback>
                  </Avatar>)
                }
              />
            )
          }}
        />

        <Modal
          title="修改显示名称"
          description={(
            <>
              <span className='block'>如有需要，显示名称在您组织或参与活动时会显示</span>
              {/* <span className='block'>请确保您的名称合规</span> */}
            </>
          )}
          triggerButton={(
            <div className='text-3xl cursor-pointer'>
              <div className='text-3xl cursor-pointer flex items-center group py-2 px-4 rounded-lg hover:bg-background'>
                <span>{account?.displayName}</span>
                <span className='ml-2 opacity-0 group-hover:opacity-60'> <EditIcon /> </span>
              </div>
            </div>
          )}
          render={({ close }) => {
            return <DisplayNameForm account={account} mutate={mutate} close={close} />
          }}
        />
      </div>

      {/* user settings */}
      <div className='flex mt-8 phone:flex-col phone:p-2'>
        <div className="pad:w-36 w-48 text-xl self-start">用户设置</div>
        <div className='flex-grow phone:mt-4'>
          {account && <BasicForm account={account} mutate={mutate} />}
        </div>
      </div>

      {/* actions */}
      <div className='flex mt-16 phone:flex-col phone:p-2'>
        <div className="pad:w-36 w-48 text-xl self-start">操作</div>
        <div className='flex-grow space-y-4 phone:mt-4'>
          <div className="flex flex-row items-center justify-between rounded-lg border p-4">
            <div className="space-y-0.5">
              <div>退出登录</div>
              <div className='text-xs text-muted-foreground'>
                退出登录后只能访问公开内容
              </div>
            </div>
            <Button variant="secondary" className="block" onClick={() => {
              logout()
              navigate("/")
              toast("Logged Out", { closeButton: true })
            }}>
              退出登录
            </Button>
          </div>

          <div className="flex flex-row items-center justify-between rounded-lg border p-4">
            <div className="space-y-0.5">
              <div>重置密码</div>
              <div className='text-xs text-muted-foreground'>
                通过验证老密码来设置新的登录密码
              </div>
            </div>
            <Modal
              title="重置密码"
              // description={(
              //   <>
              //     <span className='block'>请</span>
              //     <span className='block'>当前不支持修改 Username，那是你的登录标识</span>
              //   </>
              // )}
              triggerButton={(
                <Button variant="secondary" className="block">
                  重置密码
                </Button>
              )}
              render={({ close }) => {
                return <ResetPasswordForm account={account} mutate={mutate} close={close} />
              }}
            />
          </div>

          {/* <div className="flex flex-row items-center justify-between rounded-lg border p-4">
            <div className="space-y-0.5">
              <div>完全删除账户</div>
              <div className='text-xs text-muted-foreground'>
                删除账户后无法恢复，请谨慎操作
              </div>
            </div>
            <Modal
              title="完全删除账户"
              description={"完全删除账户会彻底删除您账号下的所有信息，删除后任何人也无法为您恢复数据，包括官方技术人员，您可以参考隐私策略了解更多详情。"}
              triggerButton={(
                <Button variant="destructive" className="block">
                  <span className='hidden lg:inline'>删除账户并清空所有数据</span>
                  <span className='inline lg:hidden'>删除账户</span>
                </Button>
              )}
              render={({ close }) => {
                return <div>功能尚未实现，Todo</div>
              }}
            />
          </div> */}

        </div>
      </div>
    </div>
  )
}

